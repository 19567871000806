import { ChangeEventHandler, FormEventHandler, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TransparentTextField from "../../../components/Inputs/TransparentTextField";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { useForgotMyPasswordMutation } from "../../../features/users/usersAPI";
import { useSnackbar } from "notistack";
import Fade from "@mui/material/Fade";

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [requestSent, setRequestSent] = useState(false);

  const [username, setUsername] = useState("");

  const [iForgotIt, { isLoading }] = useForgotMyPasswordMutation();

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setUsername(value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (e.target instanceof HTMLFormElement && e.target.reportValidity()) {
      try {
        const res = await iForgotIt({ username }).unwrap();
        setRequestSent(true);
        if (res.status === "success") {
          enqueueSnackbar("Reset Password Instructions Sent!", {
            variant: "info",
          });
        }
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Unknown Error Occured!", { variant: "error" });
      }
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        mt: 8,
        mb: 1,
        position: "relative",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={0}>
          Forgot your password?
        </Typography>

        {!requestSent && (
          <Typography variant="subtitle1" color="text.secondary">
            Enter your email
          </Typography>
        )}

        <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <Fade
            unmountOnExit
            in={!requestSent}
            onExit={(node) => (node.style.position = "absolute")}
          >
            <Box>
              <TransparentTextField
                size="small"
                margin="normal"
                required
                fullWidth
                label="Email"
                name="EmailAddress"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={handleTextChange}
              />
              <LoadingButton
                loading={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Instructions
              </LoadingButton>
            </Box>
          </Fade>
          <Fade unmountOnExit in={requestSent}>
            <Typography variant="body1" marginY={2}>
              Please check your email for reset instructions
            </Typography>
          </Fade>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "0.1rem",
            }}
          >
            <Link
              component={RouterLink}
              to="/login"
              sx={{
                color: (theme) => theme.palette.secondary.main,
              }}
              aria-label="Navigate to the Login Page"
            >
              Remember your password?
            </Link>
            <Link
              color="secondary"
              href="https://www.hunterliberty.com/contact-us-2"
              data-testid="contactLink"
            >
              Contact Us
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
