import { useRef, useEffect, useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import {
  toggleMode,
  setIsSmallDevice,
  setIsMediumDevice,
} from "../features/view/viewSlice";
import { selectFilteredSitesSelector } from "../features/sites/sitesAPI";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useSelectFilteredSitesSelector = () => {
  const selector = useMemo(() => {
    return selectFilteredSitesSelector;
  }, []);
  return selector;
};

let checkedColorPref = false;
let checkedDeviceSize = false;
export const useCheckColorPreference = () => {
  const prefersDarkMode = useRef(useMediaQuery("(prefers-color-scheme: dark)"));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!checkedColorPref) {
      if (prefersDarkMode.current) {
        dispatch(toggleMode());
      }
      checkedColorPref = true;
    }
  }, [dispatch]);
};

export const useCheckDeviceSize = () => {
  const isSmallDevice = useRef(useMediaQuery("@media (max-width:899.95px)"));
  const isMediumDevice = useRef(useMediaQuery("@media (min-width:900px)"));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!checkedDeviceSize) {
      if (isSmallDevice.current) {
        dispatch(setIsSmallDevice(true));
      }

      if (isMediumDevice.current) {
        dispatch(setIsMediumDevice(true));
      }
      checkedDeviceSize = true;
    }
  }, [dispatch]);
};
