import { memo, useRef, useEffect } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { initFilterOperatorsThunk } from "../../features/sites/sitesSlice";
import { setCredentials } from "../../features/auth/authSlice";

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

const PrivateRoute = ({
  isAuthenticated,
  authenticationPath,
  outlet,
}: ProtectedRouteProps) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [params, setParams] = useSearchParams();

  const initFilterRan = useRef(false);
  useEffect(() => {
    if (initFilterRan.current) return;
    if (isAuthenticated) {
      initFilterRan.current = true;
      // I don't think this is needed anymore
      // once we have a username check cookies for their operator filter
      dispatch(initFilterOperatorsThunk());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const jwt = params.get("jwt");
    if (location.pathname === "/" && jwt) {
      dispatch(setCredentials({ token: jwt }));
      setParams("");
    }
  }, [params, location, dispatch, setParams]);

  // Need to check if store is expired and if the user has permissions to view expired stores
  if (isAuthenticated) {
    return outlet;
  } else {
    return (
      <Navigate
        to={{ pathname: authenticationPath }}
        replace
        // state={{ redirectTo: location }}
      />
    );
  }
};

export default memo(PrivateRoute);
