import { api } from "../../app/services/api";
import { EditablePallete } from "../view/viewSlice";

export type UserType = "user" | "admin" | "global";

export type UserInfoType = {
  user_id: number;
  account_creation_date: string;
  username: string;
  user_type: UserType;
  display_name: string | null;
  chain_id: number | null;

  phone_number: string | null;
  phone_carrier: string | null;

  hidden_account: 1 | 0;
  disabled_account: 1 | 0;

  admin_permission: number[] | null; // only given to user_type="admin"
  user_store_permissions:
    | {
        store_num: number;
        permissions: number[];
      }[]
    | null;
};

export type NewUserType = Omit<
  UserInfoType,
  "user_id" | "account_creation_date" | "user_store_permissions"
> & {
  password: string | null;
};

export type EditUserType = NewUserType & {
  user_id: number;
};

export const TransformUserInfoTypeToEditUserType = (
  user: UserInfoType,
): EditUserType => {
  return {
    user_id: user.user_id,
    chain_id: user.chain_id,
    username: user.username,
    user_type: user.user_type,
    display_name: user.display_name,
    admin_permission: user.admin_permission,
    phone_number: user.phone_number,
    phone_carrier: user.phone_carrier,
    hidden_account: user.hidden_account,
    disabled_account: user.disabled_account,
    password: null,
  };
};

export type GetAllUsersType = UserInfoType;

export type GetAllUsersResponseType = GetAllUsersType[];

export type ManageUserArgsType = UserType;

export type ManageUserStoresArgsType = {
  id: number;
  stores: {
    action: "add" | "remove";
    store_marker_id: number;
    chain_id: number;
  }[];
};

export type GetStoreListForUserArgs = {
  user_id: string;
};
export type GetStoreListForUserRes = {
  store_list: string[];
};
export type GetStoreListForUserTransformedRes = {
  store_list: number[];
};

export type ResetPasswordRequest = {
  password: string;
};
export type ResetUsernameRequest = {
  new_email: string;
  password: string;
};
export type ResetUsernameResponse = {
  status: "success" | "failed";
};
export type ResetPasswordResponse = ResetUsernameResponse;

export type ForgotMyPasswordRequest = {
  username: string;
};
export type ForgotMyPasswordResponse = ResetPasswordResponse;
export type ForgotMyPasswordResetRequest = ForgotMyPasswordRequest & {
  recovery_password: string;
  password: string;
};

export type PhoneCarrier = { provider: string; gateway: string };
export type GetPhoneCarriersResponse = PhoneCarrier[];

export type GetAvailableUserEmailsResponse = { username: string }[];

type PreferenceCategories = "layout" | "appearance";
type PreferenceNames = "disableDashboardMap" | "customTheme";

export type DisableDashboardMapPreference = {
  preference_category: "layout";
  preference_name: "disableDashboardMap";
  preference_value: boolean; // or "0" | "1"
};
export type CustomThemePreference = {
  preference_category: "appearance";
  preference_name: "customTheme";
  preference_value: EditablePallete;
};
export type SaveCustomThemePreference = {
  preference_category: "appearance";
  preference_name: "customTheme";
  preference_value: string;
};

type UniqueArray<T> = T extends readonly [infer X, ...infer Rest]
  ? InArray<Rest, X> extends true
    ? ["Encountered value with duplicates:", X]
    : readonly [X, ...UniqueArray<Rest>]
  : T;

type InArray<T, X> = T extends readonly [X, ...infer _Rest]
  ? true
  : T extends readonly [X]
  ? true
  : T extends readonly [infer _, ...infer Rest]
  ? InArray<Rest, X>
  : false;

export type RawUserPreferences =
  | SaveCustomThemePreference
  | DisableDashboardMapPreference;

export type ParsedUserPreferences =
  | DisableDashboardMapPreference
  | CustomThemePreference;

export type GetUserPreferencesRequest = {
  user_id: number;
};
export type GetUserPreferencesInitialResponse = {
  preference_name: PreferenceNames;
  preference_category: PreferenceCategories;
  preference_value: string;
}[];
export type GetUserPreferencesResponse = ParsedUserPreferences[];

export type SetUserPreferencesRequest = {
  user_id: number;
  preferences: UniqueArray<RawUserPreferences[]>;
};

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<GetAllUsersResponseType, void>({
      query: () => ({
        url: "users/user",
        method: "POST",
        body: {},
      }),
      providesTags: ["Users"],
    }),
    getUserById: builder.query<GetAllUsersResponseType, { user_id: number }>({
      query: (body) => ({
        url: "users/user",
        method: "POST",
        body,
      }),
      providesTags: ["Users"],
    }),
    createUser: builder.mutation<unknown, NewUserType>({
      query: (body) => ({
        url: "users/user",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Users"],
    }),
    editUser: builder.mutation<GetAllUsersResponseType, EditUserType>({
      query: (user) => ({
        url: "users/user",
        method: "PATCH",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    deleteUser: builder.mutation<unknown, { user_id: number }>({
      query: (body) => ({
        url: "users/user",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Users"],
    }),

    resetPassword: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordRequest
    >({
      query: (body) => ({
        url: "users/reset-password",
        method: "POST",
        body,
      }),
    }),
    resetUsername: builder.mutation<
      ResetUsernameResponse,
      ResetUsernameRequest
    >({
      query: (body) => ({
        url: "users/reset-username",
        method: "POST",
        body,
      }),
    }),
    forgotMyPassword: builder.mutation<
      ForgotMyPasswordResponse,
      ForgotMyPasswordRequest
    >({
      query: (body) => ({
        url: "users/forgot-my-password",
        method: "POST",
        body,
      }),
    }),
    forgotMyPasswordReset: builder.mutation<
      ForgotMyPasswordResponse,
      ForgotMyPasswordResetRequest
    >({
      query: (body) => ({
        url: "users/forgot-my-password-reset",
        method: "POST",
        body,
      }),
    }),

    getAvailablePhoneCarriers: builder.query<GetPhoneCarriersResponse, void>({
      query: () => ({
        url: "users/phone-carriers",
        method: "GET",
      }),
      keepUnusedDataFor: 120, // shouldn't really change often
    }),

    getAvailableUserEmails: builder.query<string[], { store_list: number[] }>({
      query: (body) => ({
        url: "users/store-emails",
        method: "POST",
        body,
      }),
      transformResponse: (response: GetAvailableUserEmailsResponse) => {
        return response.map((user) => user.username);
      },
    }),

    getUserPreferences: builder.query<
      GetUserPreferencesResponse,
      GetUserPreferencesRequest
    >({
      query: (body) => ({
        url: "users/preferences",
        method: "POST",
        body,
      }),
      providesTags: ["UserPreferences"],
      transformResponse(
        baseQueryReturnValue: GetUserPreferencesInitialResponse,
      ) {
        const parsedValues: GetUserPreferencesResponse = [];
        for (const preference of baseQueryReturnValue) {
          switch (preference.preference_name) {
            case "disableDashboardMap":
              (
                preference as unknown as DisableDashboardMapPreference
              ).preference_value =
                preference.preference_value.toLowerCase() === "true";
              break;
            case "customTheme":
              try {
                const value = JSON.parse(
                  preference.preference_value,
                ) as EditablePallete;

                (
                  preference as unknown as CustomThemePreference
                ).preference_value = value;
              } catch (err) {
                console.error(err);
              }
              break;
          }

          parsedValues.push(preference as unknown as ParsedUserPreferences);
        }
        return parsedValues;
      },
    }),
    setUserPreferences: builder.mutation<unknown, SetUserPreferencesRequest>({
      query: (body) => ({
        url: "users/preferences",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UserPreferences"],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useCreateUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useResetPasswordMutation,
  useResetUsernameMutation,
  useForgotMyPasswordMutation,
  useForgotMyPasswordResetMutation,
  useGetAvailablePhoneCarriersQuery,
  useGetAvailableUserEmailsQuery,
  useGetUserPreferencesQuery,
  useSetUserPreferencesMutation,
} = usersApi;
