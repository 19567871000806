import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { DateTime } from "luxon";
import {
  MouseEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { selectAdminType } from "../../../../../features/auth/authSlice";
import { SiteType } from "../../../../../features/sites/sitesAPI";
import {
  clearOutFocusStoreState,
  selectStoreToFocus,
} from "../../../../../features/view/viewSlice";
import useTansitionNavigate from "../../../../../hooks/useTransitionNavigate";
import smoothScroll from "../../../../../utils/smoothScroll";
import SiteMenuList from "./SiteMenuList";

type Props = {
  site: SiteType;
  index: number;
  isScrolling: boolean;
  isVisible: boolean;
  isMobileScreen: boolean;
};

type SiteListItemProps = {
  expand: string;
} & ListItemProps;

const SiteListItem = styled(ListItem)<SiteListItemProps>(
  ({ theme, expand }) => ({
    ...(expand === "true" && { boxShadow: theme.shadows[5] }),
  }),
);

type SiteListItemButtonTypeProps = {
  inalarm: string;
  index: number;
} & ListItemButtonProps;

const SiteListItemButton = styled(ListItemButton)<SiteListItemButtonTypeProps>(
  ({ theme, inalarm }) => ({
    // [`&.${listItemButtonClasses.root}`]: {
    //   // "&:hover": {
    //   //   backgroundColor: "transparent",
    //   // },
    //   "&:hover:not(:has(button:hover))": {
    //     backgroundColor: theme.palette.action.hover,
    //   },
    // },
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    transition: "all 0.2s ease-in-out",
    "&:hover, &.Mui-selected:hover": {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
      //filter: 'brightness(125%)',
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.contrastText,
      //filter: 'brightness(125%)',
    },
    ...(inalarm === "true" && {
      paddingLeft: theme.spacing(2),
      backgroundColor: theme.palette.error.main, // was theme.palette.mode
      color: theme.palette.error.contrastText,
      transition: "all 0.2s ease-in-out",

      "&:hover, &.Mui-selected:hover": {
        backgroundColor: theme.palette.error.light, // was theme.palette.mode
        color: theme.palette.error.contrastText,
        // filter: 'brightness(125%)',
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette.error.dark, // was theme.palette.mode
        color: theme.palette.error.contrastText,
        // filter: 'brightness(125%)',
      },
    }),
  }),
);

const Site = ({
  site,
  index,
  isScrolling,
  isVisible,
  isMobileScreen,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useTansitionNavigate();
  const location = useLocation();

  const adminType = useAppSelector(selectAdminType);
  const storeToFocus = useAppSelector(selectStoreToFocus);

  const [expand, setExpand] = useState(false);
  const [scrollin, setScrollin] = useState(false);

  const isInAlarm = useMemo(() => {
    return site.active_alarm_count > 0;
  }, [site.active_alarm_count]);

  const isExpired = useMemo(() => {
    if (site.cloud_end_date) {
      const today = DateTime.now()
        .setZone("utc")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const expirationDate = DateTime.fromISO(site.cloud_end_date).setZone(
        "utc",
      );
      if (today >= expirationDate) {
        return true;
      }
    }
    return false;
  }, [site.cloud_end_date]);

  function navigateToSiteView() {
    if (site.store_name !== "No Sites Found") {
      const url = `/site/${site.store_num}/${encodeURIComponent(
        `${site.store_name} - ${site.store_city}, ${site.store_state}`,
      )}`;
      if (location.pathname !== url) {
        navigate(url, { state: { site } });
      }
    }
  }

  function toggleExpand() {
    if (!scrollin) {
      if (expand) {
        setExpand(!expand);
      } else {
        setScrollin(true);

        setExpand(!expand);
        navigateToSiteView();
      }
    }
  }

  const toggleExpandOnly: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!scrollin) {
      if (expand) {
        setExpand(!expand);
      } else {
        setScrollin(true);
        setExpand(!expand);
      }
    }
  };

  const listRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoFocus = useCallback(async () => {
    const element = listRef.current;
    if (element) {
      await smoothScroll(element, {
        block: "start",
        behavior: "smooth",
        inline: "start",
      }).then(() => {
        setScrollin(false);
      });
    } else {
      console.log("%cElement is null!!! (scrollIntoFocus)", "color: red");
    }
  }, []);

  useEffect(() => {
    if (expand && storeToFocus === site.store_num) {
      // after we have expanded lets clear this storeToFocus from the ViewState
      // add a check to confirm it is scrolled into view
      dispatch(clearOutFocusStoreState());
    }

    // collapse any stores that are expanded but not focused
    if (
      (expand &&
        typeof storeToFocus === "number" &&
        storeToFocus !== site.store_num) ||
      (expand && !isVisible)
    ) {
      setExpand(false);
    }

    if (
      storeToFocus === site.store_num &&
      !expand &&
      !isScrolling &&
      (!isExpired || (isExpired && adminType === "global"))
    ) {
      setExpand(true);
    }
  }, [
    storeToFocus,
    site,
    expand,
    dispatch,
    scrollIntoFocus,
    isScrolling,
    isExpired,
    adminType,
    isVisible,
  ]);

  if (site.store_name === "No Sites Found") {
    return (
      <List component="div" ref={listRef} disablePadding>
        <ListItem key={site.store_name} disablePadding>
          <ListItemButton
            selected={expand}
            id={`${site.store_name}_list-button`}
            onClick={toggleExpand}
          >
            <ListItemText
              primary={site.store_name}
              primaryTypographyProps={{
                textAlign: "center",
                color: "text.secondary",
                fontStyle: "italic",
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    );
  }

  return (
    <List
      id="storenamediv"
      component="div"
      sx={{
        scrollMarginTop: 48,
      }}
      ref={listRef}
      disablePadding
    >
      <SiteListItem
        expand={expand.toString()}
        key={`${site.store_name}${index}`}
        disablePadding
      >
        <SiteListItemButton
          selected={expand}
          id={`${site.store_name}_list-button`}
          onClick={toggleExpand}
          inalarm={isInAlarm.toString()}
          index={index}
          aria-disabled={isExpired}
          disabled={isExpired}
          sx={[
            adminType === "global" && {
              "&.Mui-disabled": {
                pointerEvents: "auto",
                cursor: "pointer",
              },
            },
          ]}
        >
          {expand ? (
            <ListItemIcon
              onClick={toggleExpandOnly}
              sx={{
                minWidth: 0,
                padding: 0.5,
                marginRight: 0,
                marginLeft: 2,
                borderRadius: "100%",
                transition: (theme) =>
                  theme.transitions.create("background-color"),
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
                color: "inherit",
              }}
            >
              <RemoveOutlinedIcon />
            </ListItemIcon>
          ) : (
            <ListItemIcon
              onClick={toggleExpandOnly}
              sx={{
                minWidth: 0,
                padding: 0.5,
                marginRight: 0,
                marginLeft: 2,
                borderRadius: "100%",
                transition: (theme) =>
                  theme.transitions.create("background-color"),
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
                color: "inherit",
              }}
            >
              <AddOutlinedIcon />
            </ListItemIcon>
          )}

          <ListItemText
            sx={{
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            primary={site.store_name}
            secondary={`${site.store_city}, ${site.store_state}`}
            secondaryTypographyProps={{
              sx: {
                color: `rgba(255,255,255,0.7)`,
              },
            }}
          />

          {site.offline && (
            <ListItemIcon>
              <CloudOffIcon />
            </ListItemIcon>
          )}

          <ListItemIcon
            sx={{
              minWidth: 0,
              borderRadius: "100%",
              padding: 1,
              transition: (theme) =>
                theme.transitions.create("background-color"),
              "&:hover": {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
              color: "inherit",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigateToSiteView();
            }}
          >
            <PageviewOutlinedIcon />
          </ListItemIcon>
        </SiteListItemButton>
      </SiteListItem>

      <SiteMenuList
        /* Need to pass store data {controllers, alarms} */
        scrollIntoFocus={scrollIntoFocus}
        expand={expand}
        siteName={site.store_name}
        controllers={site.controllers}
        siteId={site.store_num}
        city={site.store_city}
        state={site.store_state}
        terminalModeEnabled={site.terminal_mode_enabled}
        floorplanEnabled={site.floor_plan_enabled}
        leoInstalled={site.leo_installed}
        inAlarm={isInAlarm}
        activeAlarmCount={site.active_alarm_count}
        scrollin={scrollin}
        isMobileScreen={isMobileScreen}
        controllersInAlarm={site.controllers_in_alarm}
      />
    </List>
  );
};

export default memo(Site);
