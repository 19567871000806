import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import PasswordInput from "../../../components/Inputs/PasswordInput";
import TransparentTextField from "../../../components/Inputs/TransparentTextField";
import {
  selectAuthToken,
  selectUsername,
} from "../../../features/auth/authSlice";
import { useResetUsernameMutation } from "../../../features/users/usersAPI";
import useCheckIfMatchThenNavigate from "../../../hooks/appHooks/useCheckIfMatchThenNavigate";
import { isEmail } from "validator";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { enqueueSnackbar } from "notistack";
import { isFetchBaseQueryError } from "../../../utils/typeChecks";

const ResetUsername = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const username = useAppSelector(selectUsername);
  const token = useAppSelector(selectAuthToken);

  const navigateAway = useCheckIfMatchThenNavigate(
    token === "" || username === "",
    "/login",
  );

  const [invalidEmail, setInvalidEmail] = useState(false);

  const [newEmail, setNewEmail] = useState("");
  const [newEmailCheck, setNewEmailCheck] = useState("");
  const [password, setPassword] = useState("");

  const [resetUsername, { isLoading }] = useResetUsernameMutation();

  if (navigateAway) {
    return navigateAway;
  }

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "newEmail":
        setNewEmail(value);
        break;
      case "newEmailCheck":
        setNewEmailCheck(value);
        break;
      case "password":
        setPassword(value);
        break;
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (e.target instanceof HTMLFormElement && !e.target.reportValidity()) {
      return;
    }
    if (!isEmail(newEmail)) {
      setInvalidEmail(true);
      return;
    }

    try {
      const res = await resetUsername({
        new_email: newEmail,
        password,
      }).unwrap();
      if (res.status === "success") {
        enqueueSnackbar(`Username Successfully changed to ${newEmail}`, {
          variant: "success",
        });
        navigate("/login", {
          state: {
            redirectTo: location?.state?.redirectTo ?? undefined,
          },
        });
      }
      console.log({ resetUsernameRes: res });
    } catch (err) {
      console.log(err);
      if (isFetchBaseQueryError(err)) {
        if (
          (err.data as { error: string | undefined })?.error &&
          (err.data as { error: string }).error.toLowerCase() ===
            "duplicate entry for username"
        ) {
          enqueueSnackbar(
            "This email address is already associated with another account. Please use a different one.",
            { variant: "error", autoHideDuration: 10000 },
          );
          return;
        }
      }
      enqueueSnackbar(
        "An error occurred while trying to update your username.\n Please check your password and try again.\n If the issue persists, contact support.ailed to update username",
        { variant: "error", autoHideDuration: 10000 },
      );
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        mt: 8,
        mb: 1,
        position: "relative",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Tooltip title="We're updating our platform to improve security and simplify account management. Your new username must be a valid email address.">
          <InfoIcon
            fontSize="small"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "text.secondary",
              transform: "translate(-50%, 50%)",
            }}
          />
        </Tooltip>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PasswordOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Update Username
        </Typography>
        <Typography variant="subtitle1" marginTop={1}>
          Hey, we noticed your username is not a valid email address. Please
          enter an email address below to use as your username for managing your
          LEOCloud account.
        </Typography>
        <Typography variant="subtitle2" marginTop={1} color="text.secondary">
          This email address will be used for all communications including but
          not limited to alerts, reports and two-factor authentication
        </Typography>
        <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <TransparentTextField
            size="small"
            margin="normal"
            required
            fullWidth
            id="newUsername"
            label="Email Address"
            name="newEmail"
            autoComplete="email"
            autoFocus
            value={newEmail}
            onChange={handleTextChange}
            type={"email"}
            inputMode="email"
            error={invalidEmail}
            helperText={
              invalidEmail ? "Please enter a valid email address." : undefined
            }
          />

          <TransparentTextField
            size="small"
            required
            fullWidth
            id="newUsernameCheck"
            label="Confirm Email Address"
            name="newEmailCheck"
            autoComplete="email"
            value={newEmailCheck}
            onChange={handleTextChange}
            type={"email"}
            inputMode="email"
            error={newEmailCheck !== newEmail}
            helperText={
              newEmailCheck !== newEmail
                ? "Email addresses do not match."
                : undefined
            }
          />

          <PasswordInput
            size="small"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Current Password"
            name="password"
            autoComplete="current-password"
            value={password}
            onChange={handleTextChange}
          />
          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Update Username
          </LoadingButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              href="https://www.hunterliberty.com/contact-us-2"
              color="secondary"
            >
              Contact Us
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetUsername;
