import { ElementType, useMemo } from "react";
import Paper, { PaperProps } from "@mui/material/Paper";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SiteControllerType } from "../../../features/sites/sitesAPI";
import { styled, lighten } from "@mui/material/styles";
import Img from "../../../components/Utility/Img";
import { Link as RouterLink, To } from "react-router-dom";

import E2IMG from "../../../assets/ControllerImages/E2.png";
import E3IMG from "../../../assets/ControllerImages/E3.png";
import AKSC255IMG from "../../../assets/ControllerImages/AKSC255.png";
import SM880IMG from "../../../assets/ControllerImages/SM880.png";
import SM800AIMG from "../../../assets/ControllerImages/SM800A.png";
import SSIMG from "../../../assets/ControllerImages/SiteSupervisor.png";
import EVCOEV3224IMG from "../../../assets/ControllerImages/EVCO-EV3224.png";
import EVCOLIGHTINIMG from "../../../assets/ControllerImages/EVCOLighting.png";
import EVCOMONITORIMG from "../../../assets/ControllerImages/EVCOMonitor.png";
import EVCOPOWERMONITORIMG from "../../../assets/ControllerImages/EVCOPowerMonitor.png";
import EVCORTUIMG from "../../../assets/ControllerImages/EVCORTU.png";
import EVCOPUMPSTATIONIMG from "../../../assets/ControllerImages/EVCO_PUMP_STATION.png";
import HGMMZIMG from "../../../assets/ControllerImages/hgmmz.jpeg";
import EVCOHGMMZ from "../../../assets/ControllerImages/EVCOHGMMZ.png";
import EVCOHGMSZ from "../../../assets/ControllerImages/EVCOHGMSZ.png";
import EVCOCONDENSINGUNIT from "../../../assets/ControllerImages/EVCOCondensingUnit.png";
// import HEATCRAFTIMG from "../../../assets/ControllerImages/HEATCRAFT.jpeg"
import EVCOKILONODEIMG from "../../../assets/ControllerImages/KiloNode.png";
import NAIMG from "../../../assets/ControllerImages/Image_not_available.png";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import Badge from "@mui/material/Badge";

type Props = {
  controller: SiteControllerType;
  controllersInAlarm: string[] | null;
  siteId: number;
  siteNameAndLocation: string;
};

type ControllerContainerProps = { inalarm: string } & PaperProps & {
    component: ElementType;
    to: To;
  };

const ControllerImages = {
  E2: E2IMG,
  E3: E3IMG,
  SiteSupervisor: SSIMG,
  AKSC255: AKSC255IMG,
  EV3224: EVCOEV3224IMG,
  "EVCO Monitor": EVCOMONITORIMG,
  "EVCO Power Monitor": EVCOPOWERMONITORIMG,
  "EVCO RTU": EVCORTUIMG,
  "EVCO RTU Zones": EVCORTUIMG,
  "EVCO RTU V2": EVCORTUIMG,
  "EVCO RTU Zones V2": EVCORTUIMG,
  "EVCO Lighting": EVCOLIGHTINIMG,
  "EVCO Lighting V2": EVCOLIGHTINIMG,
  KiloNode: EVCOKILONODEIMG,
  "EVCO Pump Station": EVCOPUMPSTATIONIMG,
  SM880: SM880IMG,
  SM880A: SM880IMG,
  SM800A: SM800AIMG,
  "HGM-MZ": HGMMZIMG,
  "EVCO HGM-MZ": EVCOHGMMZ,
  "EVCO HGM-SZ": EVCOHGMSZ,
  "EVCO Condensing Unit": EVCOCONDENSINGUNIT,
};

const ControllerContainer = styled(Paper)<ControllerContainerProps>(
  ({ theme, inalarm }) => ({
    display: "flex",
    flexDirection: "column",
    padding: 1,
    cursor: "pointer",
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.divider}`,
    minWidth: 100,
    minHeight: 100,
    flexShrink: 0,
    maxWidth: 300,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.15),
    },
    ...(inalarm === "true" && {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.getContrastText(theme.palette.error.main),
      "&:hover": {
        backgroundColor: lighten(theme.palette.error.main, 0.15),
      },
    }),
  }),
);

type OfflineOverlayProps = BoxProps & {
  offline: string;
};
const OfflineOverlay = styled(Box)<OfflineOverlayProps>(
  ({ offline, theme }) => ({
    position: "absolute",
    // boxSizing: "content-box",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    borderWidth: "inherit",
    borderStyle: "inherit",
    borderColor: "black",
    borderRadius: "inherit",
    padding: "inherit",
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "rgba(125, 125, 125, 0.5)",
    ...(offline === "true" && {
      display: "flex",
      "&:hover": {
        backgroundColor: lighten("rgba(125, 125, 125, 0.5)", 0.15),
      },
    }),
  }),
);

const SiteController = ({
  controller,
  siteId,
  siteNameAndLocation,
  controllersInAlarm,
}: Props) => {
  const controllerUrl = useMemo(() => {
    return `/site/${siteId}/${encodeURIComponent(
      siteNameAndLocation,
    )}/controller/${encodeURIComponent(controller.controller_name)}`;
  }, [siteId, siteNameAndLocation, controller]);

  const imgSrc = useMemo(() => {
    if (
      controller.controller_type !== null &&
      ControllerImages[
        controller.controller_type as keyof typeof ControllerImages
      ]
    ) {
      return ControllerImages[
        controller.controller_type as keyof typeof ControllerImages
      ];
    }
    return NAIMG;
  }, [controller.controller_type]);

  return (
    <ControllerContainer
      inalarm={
        controllersInAlarm
          ? `${controllersInAlarm.includes(controller.controller_name)}`
          : `${false}`
      }
      component={RouterLink}
      to={controllerUrl}
      elevation={6}
      aria-label="Navigate to Controller's Page"
    >
      <Badge
        invisible={controller.offline !== 1}
        showZero
        badgeContent={<CloudOffOutlinedIcon sx={{ color: "grey" }} />}
      >
        <Box
          sx={{
            width: "100%",
            position: "relative",
            minWidth: 240,
            textAlign: "center",
          }}
        >
          <Img
            alt={`${controller.controller_type ?? "Unknown Device"} Image`}
            src={imgSrc}
            sx={{
              position: "relative",
              objectFit: "contain",
              width: "100%",
              maxHeight: 200,
              backgroundColor: "#ccc",
            }}
          />
        </Box>
      </Badge>

      <Typography variant="h5" textAlign="center">
        {controller.controller_name}
      </Typography>
      <OfflineOverlay offline={(controller.offline === 1).toString()}>
        <CloudOffOutlinedIcon sx={{ fontSize: "3rem" }} />
      </OfflineOverlay>
    </ControllerContainer>
  );
};

export default SiteController;
