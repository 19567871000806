import Container from "@mui/material/Container";
import { useSnackbar } from "notistack";
import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLoginMutation } from "../../../features/auth/authAPI";
import { setMinimalToken } from "../../../features/auth/authSlice";
import useTansitionNavigate from "../../../hooks/useTransitionNavigate";
import {
  isFetchBaseQueryError,
  isSerializedError,
} from "../../../utils/typeChecks";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TransparentTextField from "../../../components/Inputs/TransparentTextField";
import Typography from "@mui/material/Typography";
import PasswordInput from "../../../components/Inputs/PasswordInput";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { isEmail } from "validator";
import {jwtDecode} from "jwt-decode";
import {
  DecodedToken,
  MinimalDecodedToken,
  selectIsAuthenticated,
} from "../../../features/auth/authSlice";
import { RedirectLocationState } from "./TwoFactorCode";

const Login = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [searchParams, setSearchParams] = useSearchParams();

  const { state: locationState } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();
  const navigate = useTansitionNavigate();

  const [username, setUsername] = useState(searchParams.get("username") ?? "");
  const [password, setPassword] = useState(searchParams.get("password") ?? "");
  useEffect(() => {
    if (
      searchParams.get("username") !== null ||
      searchParams.get("password") !== null
    ) {
      console.log("Cleared Search Params");
      setSearchParams("");
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    // if the user isAuthenticated we want to force them off this page
    if (isAuthenticated) {
      if (
        locationState &&
        "redirectTo" in locationState &&
        locationState.redirectTo !== undefined
      ) {
        const { redirectTo } = locationState as RedirectLocationState;
        navigate(`${redirectTo.pathname}${redirectTo.search}`);
      } else {
        navigate("/");
      }
    }
  }, [isAuthenticated, navigate, locationState]);

  const [login, { isLoading, isError }] = useLoginMutation();

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value);
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = { user_id: username, password };

    try {
      const loginRes = await login(user).unwrap();
      switch (loginRes.status) {
        case "success": {
          const decodedToken = jwtDecode(loginRes.token) as
            | DecodedToken
            | MinimalDecodedToken;
          if (decodedToken.minimal) {
            // Only if the token is minimal do we require 2FA
            // I have a reducer in authSlice to handle the non-minimal token for login
            dispatch(
              setMinimalToken({
                token: loginRes.token,
                awaitingTwoFactor: true,
              }),
            );
            navigate("/twoFactorAuthCode", {
              state: {
                redirectTo: locationState?.redirectTo,
              },
            });
          }
          break;
        }
        case "reset username":
          dispatch(setMinimalToken({ token: loginRes.token }));
          navigate("/resetUsername", {
            state: {
              redirectTo: locationState?.redirectTo,
            },
          });
          break;
        case "set new password":
          dispatch(setMinimalToken({ token: loginRes.token }));
          navigate("/resetPassword", {
            state: {
              redirectTo: locationState?.redirectTo,
            },
          });
          break;
        case "failed":
          console.log("FAILED LOGGIN STATUS");
          console.log({ loginRes });
          // handle this
          break;
      }
    } catch (err: unknown) {
      if (isFetchBaseQueryError(err)) {
        if (typeof err.status === "number") {
          if (err.status === 401) {
            // Add Check for ingles username that isn't a email and tell them to use their email

            if (
              !isEmail(username) &&
              username.toLowerCase().includes("ingles")
            ) {
              enqueueSnackbar({
                message: "Please use your email to login",
                variant: "error",
                title: "Invalid Username",
              });
              return;
            }

            if (
              (err.data as { error: string }).error &&
              typeof (err.data as { error: string }).error === "string"
            ) {
              enqueueSnackbar({
                message: `${(err.data as { error: string }).error}`,
                variant: "error",
                title: "Error",
                autoHideDuration: 7000,
              });
              return;
            }

            enqueueSnackbar({
              message: "Invalid Login Credentials",
              variant: "error",
              title: "Error",
            });
          } else {
            enqueueSnackbar({
              message: `${err.data}`,
              variant: "error",
              title: `${err.status} Error`,
            });
          }
        } else {
          enqueueSnackbar({
            message: `${err.data}`,
            variant: "error",
            title: `${err.status} Error`,
          });
        }
      } else if (isSerializedError(err)) {
        enqueueSnackbar({
          message: `Code: ${err.code ?? "N/A"} Name: ${err.name ?? "N/A"}
                 Message: ${err.message ?? "unknown?"}`,
          variant: "error",
          title: "Serialized Error",
        });
      } else {
        enqueueSnackbar({
          message: `An unknown error occured`,
          variant: "error",
          title: "Error",
        });
      }
    }
  };

  return (
    <>
      <Alert
        sx={{ maxWidth: 520, marginX: "auto", mt: 7 }}
        variant="standard"
        severity="info"
      >
        <AlertTitle>Notice</AlertTitle>
        LEO Cloud has been updated. Please check your email for details and
        Two-Factor Authentication codes upon login.
      </Alert>

      <Container
        maxWidth="xs"
        sx={{
          //mt: 8,
          mt: 1,
          mb: 1,
          position: "relative",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography data-testid="loginHeader" component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" sx={{ mt: 1 }} onSubmit={handleLogin}>
            <TransparentTextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email or Username"
              name="username"
              autoComplete="username email"
              data-testid="usernameInput"
              autoFocus
              value={username}
              onChange={handleUsernameChange}
              error={isError}
            />
            <PasswordInput
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              data-testid="passwordInput"
              value={password}
              onChange={handlePasswordChange}
              error={isError}
            />
            <LoadingButton
              loading={isLoading}
              type="submit"
              fullWidth
              variant="contained"
              data-testid="loginBtn"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </LoadingButton>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                columnGap: "0.1rem",
              }}
            >
              <Link
                component={RouterLink}
                to="/forgotPassword"
                sx={{ color: (theme) => theme.palette.secondary.main }}
                aria-label="Navigate to the Forgot Password Page"
              >
                Forgot your password?
              </Link>
              <Link
                color="secondary"
                href="https://www.hunterliberty.com/contact-us-2"
                data-testid="contactLink"
                aria-label="Navigate to the Contact Us Page"
              >
                Contact Us
              </Link>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Login;
