import Box from "@mui/material/Box";
import { BoxProps } from "@mui/system";

type ImgProps = {
  alt: string;
  src: string;
};

const Img = (props: ImgProps & BoxProps) => <Box component="img" {...props} />;

export default Img;
