import { useRef } from "react";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import { logout } from "../../../features/auth/authSlice";
import {
  toggleStoresDrawer,
  selectShowStoresDrawer,
} from "../../../features/view/viewSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import MainMenu from "./MainMenu";
import SiteList from "./SiteList/SiteList";
import { resetHiddenChains } from "../../../features/sites/sitesSlice";

type Props = {
  isMobileScreen: boolean;
};

const Menu = ({ isMobileScreen }: Props) => {
  const dispatch = useAppDispatch();

  const showStores = useAppSelector(selectShowStoresDrawer);

  function handleStoresDrawerToggle() {
    dispatch(toggleStoresDrawer());
  }

  const handleLogout = () => {
    console.info(
      "%clogging out because logout button was pressed",
      "color: orange",
    );
    dispatch(logout());
    dispatch(resetHiddenChains());
    // clear hiddenChains
  };

  const boxRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        position: "relative",
        height: "100%",
        borderRadius: 0,
        boxShadow: 3,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        margin: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 }, //{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }
        width: { sm: "100%", xs: "100%", md: "auto", lg: "auto", xl: "auto" },
      }}
      aria-description="my menu box"
      role="presentation"
      component="div"
      ref={boxRef}
    >
      <Slide direction="right" in={!showStores} container={boxRef.current}>
        <Box
          data-testid="mainMenuContainer"
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "inherit",
            minHeight: "100%",
            overflowY: "auto",
          }}
        >
          <MainMenu
            handleLogout={handleLogout}
            handleStoresDrawerToggle={handleStoresDrawerToggle}
            showStores={showStores}
            isMobileScreen={isMobileScreen}
          />
        </Box>
      </Slide>

      <Slide in={showStores} direction="left" container={boxRef.current} appear>
        <Box
          data-testid="siteListContainer"
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "inherit",
            minHeight: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flexStart",
            overflowY: "auto",
          }}
        >
          <SiteList
            handleStoresDrawerToggle={handleStoresDrawerToggle}
            isMobileScreen={isMobileScreen}
          />
        </Box>
      </Slide>
    </Box>
  );
};

export default Menu;
