import { listenerMiddleware } from "./listenerMiddleware";
import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import viewReducer from "../features/view/viewSlice";
import authReducer from "../features/auth/authSlice";
import sitesReducer from "../features/sites/sitesSlice";
import controllersReducer from "../features/controllers/controllersSlice";
import { api, rtkQueryErrorLogger } from "../app/services/api"; // importing this before the slice reducers causes a issue with api

export const rootReducer = combineReducers({
  view: viewReducer,
  auth: authReducer,
  sites: sitesReducer,
  controllers: controllersReducer,
  [api.reducerPath]: api.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        listenerMiddleware.middleware,
        api.middleware,
        rtkQueryErrorLogger,
      );
    },
    preloadedState,
  });
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      listenerMiddleware.middleware,
      api.middleware,
      rtkQueryErrorLogger,
    );
  },
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const selectState = (state: RootState) => state;

// const buildLoaders = (api: APIType, appStore: AppStore) => {
//   api.loaders = {};
//   Object.keys(api.endpoints).forEach(endpoint => {
//     api.loaders[endpoint] = async params => {
//       const promise = appStore.dispatch(api.endpoints[endpoint].initiate(params));
//       await promise;
//       promise.unsubscribe();
//     };
//   });
// };
