import { useLocation, useParams } from "react-router-dom";
import PageTitle from "../../../components/Utility/PageTitle";
import SingleItemGridContainer from "../../../components/Utility/SingleItemGridContainer";
import { SiteType } from "../../../features/sites/sitesAPI";
import Site from "./SitePage";

type LocationStateType = {
  site?: SiteType;
  dashboardMapRect?: DOMRect;
  navigatedFrom: string;
};

const SitePageWrapper = () => {
  const { siteId, siteNameAndLocation } = useParams();
  const location = useLocation();
  const { site, dashboardMapRect, navigatedFrom } =
    (location?.state as LocationStateType) ?? {};

  if (!siteId || !siteNameAndLocation) {
    return (
      <SingleItemGridContainer>
        <PageTitle
          my={1}
          pageTitle="Something went wrong and information is missing!"
        />
      </SingleItemGridContainer>
    );
  }

  return (
    <SingleItemGridContainer
      flex={"1 1 1px"}
      alignItems="center"
      minHeight={{ sm: "unset", md: 0 }}
    >
      <Site
        key={`${siteId}`}
        siteId={+siteId}
        siteNameAndLocation={siteNameAndLocation}
        site={site}
        dashboardMapRect={dashboardMapRect}
        navigatedFrom={navigatedFrom}
      />
    </SingleItemGridContainer>
  );
};

export default SitePageWrapper;
