import { api } from "../../app/services/api";

export interface LoginRequest {
  user_id: string;
  password: string;
}
export interface LoginResponse {
  status: "reset username" | "set new password" | "success" | "failed";
  token: string;
}

export type TwoFactorRequest = {
  username: string;
  two_factor_code: number;
};
export type TwoFactorResponse = {
  status: "success" | "failed";
  token: string;
};

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: "login/login",
        method: "POST",
        body: credentials,
      }),
    }),
    twoFactor: builder.mutation<LoginResponse, TwoFactorRequest>({
      query: (arg) => ({
        url: "login/two-factor-auth",
        method: "POST",
        body: arg,
      }),
    }),
    refreshToken: builder.mutation<LoginResponse, void>({
      query: () => ({
        url: "login/refresh-token",
        method: "POST",
      }),
    }),
    updateToken: builder.query<LoginResponse, void>({
      query: () => ({
        url: "login/update-token",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useTwoFactorMutation,
  useUpdateTokenQuery,
  useLazyUpdateTokenQuery,
} = authApi;
