import {
  useNavigate,
  NavigateOptions,
  To,
  useLocation,
} from 'react-router-dom';
import { startTransition, useCallback } from 'react';

export default function useTansitionNavigate() {
  const navigate = useNavigate();
  const location = useLocation();
  const transitionNavigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      if (options) {
        if (options.state) {
          if ('navigatedFrom' in options.state && options.state.navigatedFrom) {
            startTransition(() => {
              navigate(to, options);
            });
          } else {
            const optionsClone = structuredClone(options);
            optionsClone.state = {
              ...optionsClone.state,
              navigatedFrom: location.pathname,
            };
            startTransition(() => {
              navigate(to, optionsClone);
            });
          }
        } else {
          const optionsClone = structuredClone(options);
          optionsClone.state = { navigatedFrom: location.pathname };
          startTransition(() => {
            navigate(to, optionsClone);
          });
        }
      } else {
        const myOptions = { state: { navigatedFrom: location.pathname } };
        startTransition(() => {
          navigate(to, myOptions);
        });
      }
    },
    [navigate, location],
  );
  return transitionNavigate;
}
