import { forwardRef } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import type { ToggleButtonProps } from "@mui/material/ToggleButton";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleMode, selectTheme } from "../../../features/view/viewSlice";

type Props = Omit<ToggleButtonProps, "value">;

const ToggleColorPreference = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const colorMode = useAppSelector(selectTheme);
    const dispatch = useAppDispatch();

    const handleColorModeToggle = () => dispatch(toggleMode());

    return (
      <ToggleButton
        {...props}
        ref={ref}
        value={colorMode}
        title="Toggle Color Preference"
        aria-label="Toggle Color Preference"
        onClick={handleColorModeToggle}
      >
        {colorMode === "light" ? <DarkModeIcon /> : <LightModeIcon />}
      </ToggleButton>
    );
  },
);

export default ToggleColorPreference;
