import { useState, useMemo } from "react";
import { TextFieldProps } from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import TransparentTextField from "./TransparentTextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";

type Props = TextFieldProps & { showStrength?: boolean; enforceMin?: boolean };

const widths = ["5%", "30%", "55%", "80%", "100%"];
const colors = ["#D73F40", "#DC6551", "#F2B84F", "#BDE952", "#3ba62f"];
const arrayTest = [/[0-9]/, /[a-z]/, /[A-Z]/, /[^0-9a-zA-Z]/];
function PasswordStrengthCalculator({ value }: { value: string }) {
  const points = useMemo(() => {
    let points = 0;
    for (const test of arrayTest) {
      if (test.test(value)) {
        points += 1;
      }
    }
    return points;
  }, [value]);
  const width = useMemo(() => {
    return widths[points];
  }, [points]);
  const backgroundColor = useMemo(() => {
    return colors[points];
  }, [points]);

  return (
    <Box
      position={"relative"}
      border="1"
      borderRadius={3}
      marginX={"0.25rem"}
      marginTop={"0.1rem"}
      display={value === "" ? "none" : "flex"}
      flexDirection="column"
    >
      <Typography color="text.secondary" fontSize={"0.75rem"}>
        Password Strength
      </Typography>
      <Box
        position={"relative"}
        height={"0.2rem"}
        width={width}
        sx={{ backgroundColor }}
      ></Box>
    </Box>
  );
}

export default function PasswordInput({
  InputProps,
  showStrength = false,
  enforceMin = false,
  ...props
}: Props) {
  const [showPass, setShowPass] = useState(false);
  const toggleShowPass = () => setShowPass((prev) => !prev);

  return (
    <>
      <TransparentTextField
        title={enforceMin ? "Ten or more characters" : undefined}
        inputProps={
          enforceMin
            ? {
                pattern: ".{8,}",
              }
            : undefined
        }
        type={showPass ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={toggleShowPass}>
                {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...props}
      />
      {showStrength && (
        <PasswordStrengthCalculator value={props.value as string} />
      )}
    </>
  );
}
