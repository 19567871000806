export const getCookie = (cookieName: string) => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cName, cVal] = cookie.trim().split("=");
    if (cookieName === cName) {
      return cVal;
    }
  }
  return undefined;
};

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expiration = "Thu, 18 Dec 2100 12:00:00 UTC",
) => {
  document.cookie = `${cookieName}=${cookieValue}; expires=${expiration}; path=/; samesite=strict;`;
};
