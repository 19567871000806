import { useRef } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import bgImg from "../../assets/scaledx1-720p.jpg";
import Img from "../Utility/Img";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

type AnimatedCloudProps = BoxProps & {
  size: "1" | "2" | "3" | "4" | "5" | "6";
};

const AnimatedCloud = styled(Box)<AnimatedCloudProps>(({ size = "1" }) => ({
  background: "#fff",
  filter:
    "progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#f1f1f1',GradientType=0 )",
  borderRadius: 100,
  boxShadow: "0 8px 5px rgba(0, 0, 0, 0.1)",
  height: 120,
  position: "relative",
  width: 350,
  "&::before , &::after": {
    background: "#fff",
    content: "''",
    position: "absolute",
    zIndex: -1,
  },
  "&::after": {
    borderRadius: 100,
    height: 100,
    left: 50,
    top: -50,
    width: 100,
  },
  "&::before": {
    borderRadius: 200,
    width: 180,
    height: 180,
    right: 50,
    top: -90,
  },
  "@keyframes animateCloud": {
    "0%": {
      marginLeft: "-1000px",
    },
    "100%": {
      marginLeft: "100%",
    },
  },
  ...(size === "1" && {
    animation: "animateCloud 35s linear infinite",
    transform: "scale(0.65)",
  }),
  ...(size === "2" && {
    animation: "animateCloud 23s linear infinite",
    transform: "scale(0.3)",
  }),
  ...(size === "3" && {
    animation: "animateCloud 33s linear infinite",
    transform: "scale(0.5)",
  }),
  ...(size === "4" && {
    animation: "animateCloud 20s linear infinite",
    transform: "scale(0.4)",
  }),
  ...(size === "5" && {
    animation: "animateCloud 28s linear infinite",
    transform: "scale(0.55)",
  }),
  ...(size === "6" && {
    animation: "animateCloud 36s linear infinite",
    transform: "scale(0.45)",
  }),
}));

/**
 * @description Layout Component used to render all of the login related pages (Before full user authentication)
 * @author Jacob Cutshall
 */
const AuthLayout = () => {
  const nodeRef = useRef(null);
  const location = useLocation();
  const currentOutlet = useOutlet();

  return (
    <div className="container">
      <Img
        src={bgImg}
        alt="background: blurry clouds"
        data-testid="background-image"
        sx={{
          // filter: 'blur(20px)',
          // webKitFilter: 'blur(20px)',
          width: "110%",
          height: "110%",
          transform: "translate(-5%, -5%)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "absolute",
          overflow: "hidden",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          marginTop: 5,
        }}
      >
        <AnimatedCloud size="1" />
        <AnimatedCloud size="2" />
        <AnimatedCloud size="3" />
        <AnimatedCloud size="4" />
        <AnimatedCloud size="5" />
        <AnimatedCloud size="6" />
      </Box>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          nodeRef={nodeRef}
          timeout={300}
          classNames="page"
          unmountOnExit
        >
          {(_state) => (
            <div ref={nodeRef} className="page">
              {currentOutlet}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default AuthLayout;
