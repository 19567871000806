import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import HomeIcon from "@mui/icons-material/Home";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link as RouterLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Logo from "../../../assets/HL_LOGO_V2.png";
import {
  selectCanViewAdminPortal,
  selectCanViewAnalytics,
} from "../../../features/auth/authSlice";
import { toggleDrawer } from "../../../features/view/viewSlice";
import NewFeatureBanner from "../../NewFeatureBanner/NewFeatureBanner";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type Props = {
  showStores: boolean;
  handleStoresDrawerToggle: () => void;
  handleLogout: () => void;
  isMobileScreen: boolean;
};

const MainMenu = ({
  showStores,
  handleStoresDrawerToggle,
  handleLogout,
  isMobileScreen,
}: Props) => {
  const dispatch = useAppDispatch();
  const canViewAdminPortal = useAppSelector(selectCanViewAdminPortal);
  const canViewAnalytics = useAppSelector(selectCanViewAnalytics);

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  const handleLinkClick = () => {
    if (isMobileScreen) {
      // hide menu
      handleToggleDrawer();
    }
  };

  return (
    <>
      <Box sx={{ marginX: 1, marginY: 1, display: "flex", columnGap: 1 }}>
        <img
          style={{
            objectFit: "contain",
            width: "90%",
            height: "auto",
            position: "relative",
            minWidth: 0,
          }}
          src={Logo}
          alt="Hunter Liberty Corporation Logo"
        />
        <IconButton
          data-testid="hideMenuBtn"
          title="hide menu"
          sx={{ alignSelf: "center", marginRight: "-5.5px" }}
          onClick={handleToggleDrawer}
        >
          <CloseRoundedIcon fontSize="large" />
        </IconButton>
      </Box>
      <List>
        <ListItem sx={{ zIndex: 1, paddingTop: 1 }} disablePadding>
          <ListItemButton
            data-testid="homeMenuLink"
            component={RouterLink}
            to={"/"}
            selected={location.pathname === "/" && !showStores}
            onClick={handleLinkClick}
            aria-label="Navigate to the Home Page"
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>

        <ListItem
          sx={showStores ? { boxShadow: 5, zIndex: 3 } : {}}
          disablePadding
        >
          <ListItemButton
            data-testid="sitesMenuLink"
            onClick={handleStoresDrawerToggle}
            selected={showStores}
          >
            <ListItemIcon>
              <LocationCityIcon />
            </ListItemIcon>
            <ListItemText primary={"Sites"} />
          </ListItemButton>
        </ListItem>

        {canViewAnalytics && (
          <ListItem disablePadding>
            <ListItemButton
              data-testid="analyticsMenuLink"
              component={RouterLink}
              to={"/analytics"}
              selected={location.pathname.includes("/analytics") && !showStores}
              onClick={handleLinkClick}
              aria-label="Navigate to the Analytics Page"
            >
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary={"Analytics"} />
            </ListItemButton>
          </ListItem>
        )}
        {canViewAdminPortal && (
          <ListItem disablePadding>
            <ListItemButton
              data-testid="adminPortalMenuLink"
              component={RouterLink}
              to={"/adminPortal"}
              selected={
                location.pathname.includes("/adminPortal") && !showStores
              }
              onClick={handleLinkClick}
              aria-label="Navigate to the Admin Portal"
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Admin Portal"} />
            </ListItemButton>
          </ListItem>
        )}
        <NewFeatureBanner>
          <ListItem disablePadding sx={{ overflow: "clip" }}>
            <ListItemButton
              data-testid="userAccountMenuLink"
              component={RouterLink}
              to={"/userAccount"}
              selected={
                location.pathname.includes("/userAccount") && !showStores
              }
              onClick={handleLinkClick}
              aria-label="Navigate to the User Account Page"
            >
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              <ListItemText primary={"User Account"} />
            </ListItemButton>
          </ListItem>
        </NewFeatureBanner>

        {/*
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={() => handleNavigate('/help')}
                        selected={location.pathname === '/help' && !showStores}
                    >
                        <ListItemIcon>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Help'} />
                    </ListItemButton>
                </ListItem>
            */}
        <Divider />

        <ListItem disablePadding>
          <ListItemButton data-testid="logOutMenuLink" onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Log Out"} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default MainMenu;
