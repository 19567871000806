import type { TypographyProps } from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { ElementType, forwardRef, memo } from "react";
import ToggleMenu from "../Layout/Menu/ToggleMenu";

// This allows us to pass props for the Typography component
// if we want some extra control over the PageTitle Component
// the component prop is annoying because it should be included in TypographyProps
interface PageTitleProps {
  pageTitle: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ElementType<any>;
}

type Props = TypographyProps & PageTitleProps;

const MyTitleBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: 1,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const PageTitle = forwardRef(
  (
    {
      pageTitle,
      variant = "h2",
      textAlign = "center",
      component = "h1",
      ...props
    }: Props,
    ref,
  ) => {
    return (
      <MyTitleBox>
        <ToggleMenu sx={{ marginTop: 1 }} />
        <Typography
          ref={ref}
          component={component}
          variant={variant}
          textAlign={textAlign}
          justifySelf="center"
          flexGrow={1}
          {...props}
        >
          {pageTitle}
        </Typography>
      </MyTitleBox>
    );
  },
);

export default memo(PageTitle);
