import Container from "@mui/material/Container";

const Help = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      Help
    </Container>
  );
};

export default Help;
