import { forwardRef } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import { SnackbarContent, CustomContentProps, closeSnackbar } from "notistack";

export interface ErrorSnackProps extends CustomContentProps {
  moreInfo?: string;
  title?: string;
}

const ErrorSnackbar = forwardRef<HTMLDivElement, ErrorSnackProps>(
  (
    {
      id,
      message,
      moreInfo,
      title,
      anchorOrigin: _aO,
      hideIconVariant: _hIV,
      iconVariant: _iV,
      autoHideDuration: _aHD,
      persist: _p,
      ...extraProps
    },
    ref,
  ) => {
    const handleDismiss = () => {
      closeSnackbar(id);
    };
    return (
      <SnackbarContent ref={ref} {...extraProps}>
        <Alert
          onClose={handleDismiss}
          severity="error"
          sx={{ width: "100%", boxShadow: 5 }}
        >
          <AlertTitle>{title ? title : "Error"}</AlertTitle>
          {message}
          {moreInfo !== undefined && (
            <Typography variant="subtitle2">{moreInfo}</Typography>
          )}
        </Alert>
      </SnackbarContent>
    );
  },
);

export default ErrorSnackbar;
