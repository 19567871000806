import { forwardRef } from "react";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  toggleDrawer,
  selectShowDrawer,
} from "../../../features/view/viewSlice";

type Props = Omit<ToggleButtonProps, "value">;

const MenuToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
  // color: theme.palette.text.primary,
  display: "inline-flex",
  width: 48,
  height: "fit-content",
  transition: theme.transitions.create(["width", "padding"]),
  ...(selected && {
    // display: "none"
    width: 0,
    padding: 0,
    border: "none",
    overflow: "hidden",
    marginRight: "0 !important",
  }),
}));

const ToggleMenu = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const dispatch = useAppDispatch();
  const showDrawer = useAppSelector(selectShowDrawer);

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  return (
    <MenuToggleButton
      value={showDrawer}
      selected={showDrawer}
      onClick={handleToggleDrawer}
      ref={ref}
      title="open menu"
      {...props}
    >
      <MenuIcon />
    </MenuToggleButton>
  );
});

export default ToggleMenu;
