import { styled } from "@mui/material/styles";
import { inputBaseClasses } from "@mui/material/InputBase";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { forwardRef } from "react";

const TransparentTextField = styled(
  forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => (
    <TextField ref={ref} color="secondary" {...props} />
  )),
)(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.1)"
        : "rgba(0,0,255,0.1)",
    transition: theme.transitions.create([
      "background-color",
      "border-color",
      "box-shadow",
    ]),
    [`&.${inputBaseClasses.focused}`]: {
      backgroundColor: "transparent",
      [`& .${inputBaseClasses.input}`]: {
        "&:-webkit-autofill": {
          transition: theme.transitions.create(["box-shadow"]),
          boxShadow:
            theme.palette.mode === "dark"
              ? "0 0 0 100px #515151 inset"
              : "0 0 0 100px #fcfcff inset",
          borderRadius: "none",
        },
      },
    },
    [`& .${inputBaseClasses.input}`]: {
      "&:-webkit-autofill": {
        // This causes browsers default color to flash ("yellow")
        //backgroundColor: "inherit",
        //transition: theme.transitions.create(['box-shadow']),
        boxShadow:
          theme.palette.mode === "dark"
            ? "0 0 0 100px #636363 inset"
            : "0 0 0 100px #e5e5ff inset",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
        "&::active": {
          transition: theme.transitions.create(["box-shadow"]),
        },
      },
    },
  },
}));

export default TransparentTextField;
