import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export function isFetchBaseQueryError(
  err: unknown,
): err is FetchBaseQueryError {
  return typeof err === "object" && err !== null && "status" in err;
}

export function isSerializedError(err: unknown): err is SerializedError {
  return (
    typeof err === "object" &&
    err !== null &&
    ("name" in err || "message" in err || "stack" in err || "code" in err)
  );
}

export function isErrorWithDataErrorString(
  err: unknown,
): err is { data: { error: string } } {
  return (
    typeof err === "object" &&
    err != null &&
    "data" in err &&
    err.data != null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof (err.data as any).error === "string"
  );
}
