import { useMemo } from "react";
import { useRouteError } from "react-router-dom";

/**
 * @description RootErrorBoundary component Used solely with react-router-dom to catch unhandled errors
 * @author Jacob Cutshall
 * @returns {*}
 */
const RouterErrorBoundary = () => {
  const error = useRouteError();
  const isChunkLoadError = useMemo(() => {
    const matchResult = (error as { message?: string })?.message?.match(
      /Loading chunk [\d]+ failed/,
    );

    return matchResult !== null && matchResult !== undefined;
  }, [error]);

  if (isChunkLoadError) {
    return (
      <div role="alert">
        <p>
          The Application has been updated. Please Refresh the page or wait 10
          seconds.
        </p>
        <button onClick={() => window.location.reload()}>Refresh</button>
      </div>
    );
  }

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>
        {(error as { message?: string })?.message
          ? (error as { message: string }).message
          : (error as { data?: never })?.data}
      </pre>
      <button onClick={() => window.location.reload()}>Try again</button>
    </div>
  );
};

export default RouterErrorBoundary;
