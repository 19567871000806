/* eslint-disable @typescript-eslint/no-unused-expressions */
import { RootState } from "../../app/store";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getCookie, setCookie } from "../../utils/cookieHandlers";
import { SiteType } from "./sitesAPI";

// Custom List
export type SiteFilterBy =
  | Exclude<
      keyof SiteType,
      | "latitude"
      | "longitude"
      | "leo_installed"
      | "floor_plan_enabled"
      | "terminal_mode_enabled"
      | "ingest_data"
      | "cloud_contacts"
      | "cloud_users_limit"
      | "cloud_start_date"
      | "cloud_end_date"
      | "active_alarm_count"
      | "critical_active_alarm_count"
      | "controllers"
      | "controllers_in_alarm"
      | "voice_sms_subscription"
      | "sms_receivers_limit"
      | "voip_receivers_limit"
      | "mqtt_path"
      | "time_zone"
      | "store_address"
    >
  | "All";
export type SitesState = {
  filteredSiteOperators: string[];
  filterBy: SiteFilterBy;
  filterValue: string;
};

export const getOperatorsFilterCookie = (cookieName: string) => {
  const cookieValUnparsed = getCookie(cookieName);
  if (cookieValUnparsed) {
    const hiddenOperators = cookieValUnparsed.split("~");
    return hiddenOperators;
  }

  return [];
};

const setOperatorFilterCookie = (cookieName: string, operators: string[]) => {
  setCookie(cookieName, operators.join("~"));
};

const initialState: SitesState = {
  filteredSiteOperators: [],
  filterBy: "All",
  filterValue: "",
};

export const initFilterOperatorsThunk = createAsyncThunk<
  { username: string },
  undefined,
  { state: RootState }
>("sites/initFilterOperatorsThunk", async (_arg, { getState }) => {
  return { username: getState().auth.username };
});

export const toggleFilterOperatorThunk = createAsyncThunk<
  { operator: string; username: string },
  string,
  { state: RootState }
>("sites/toggleFilterOperatorThunk", async (operator, { getState }) => {
  return { operator, username: getState().auth.username };
});

export const setOperatorsFilterThunk = createAsyncThunk<
  { operators: string[]; username: string },
  string[],
  { state: RootState }
>("sites/setOperatorsFilterThunk", async (operators, { getState }) => {
  return { operators, username: getState().auth.username };
});

export const resetOperatorsFilterThunk = createAsyncThunk<
  { username: string },
  undefined,
  { state: RootState }
>("sites/resetOperatorsFilterThunk", async (_arg, { getState }) => {
  return { username: getState().auth.username };
});

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    toggleHideChain: (state, action: PayloadAction<string>) => {
      const hiddenChainsCopy = state.filteredSiteOperators.concat();
      if (state.filteredSiteOperators.includes(action.payload)) {
        // remove
        const index = hiddenChainsCopy.findIndex((v) => v === action.payload);
        if (index > -1) {
          hiddenChainsCopy.splice(index, 1);
          state.filteredSiteOperators = hiddenChainsCopy;
        }
      } else {
        //add
        hiddenChainsCopy.push(action.payload);
        state.filteredSiteOperators = hiddenChainsCopy;
      }
    },
    setHiddenChains: (state, action: PayloadAction<string[]>) => {
      state.filteredSiteOperators = action.payload;
    },
    resetHiddenChains: (state) => {
      state.filteredSiteOperators = [];
    },
    setSiteFilterBy: (state, action: PayloadAction<SiteFilterBy>) => {
      state.filterBy = action.payload;
    },
    setSiteFilterValue: (state, action: PayloadAction<string>) => {
      state.filterValue = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(initFilterOperatorsThunk.fulfilled, (state, action) => {
      if (action.payload.username !== "") {
        const filteredOperators = getOperatorsFilterCookie(
          `hiddenChains~${action.payload.username}`,
        );
        state.filteredSiteOperators = filteredOperators;
      }
    }),
      builder.addCase(toggleFilterOperatorThunk.fulfilled, (state, action) => {
        const hiddenChainsCopy = state.filteredSiteOperators.concat();
        if (state.filteredSiteOperators.includes(action.payload.operator)) {
          // remove
          const index = hiddenChainsCopy.findIndex(
            (v) => v === action.payload.operator,
          );
          if (index > -1) {
            hiddenChainsCopy.splice(index, 1);
            state.filteredSiteOperators = hiddenChainsCopy;
          }
        } else {
          //add
          hiddenChainsCopy.push(action.payload.operator);
          state.filteredSiteOperators = hiddenChainsCopy;
        }

        if (action.payload.username !== "") {
          setOperatorFilterCookie(
            `hiddenChains~${action.payload.username}`,
            hiddenChainsCopy,
          );
        }
      }),
      builder.addCase(setOperatorsFilterThunk.fulfilled, (state, action) => {
        if (action.payload.username !== "") {
          setOperatorFilterCookie(
            `hiddenChains~${action.payload.username}`,
            action.payload.operators,
          );
        }
        state.filteredSiteOperators = action.payload.operators;
      }),
      builder.addCase(resetOperatorsFilterThunk.fulfilled, (state, action) => {
        if (action.payload.username !== "") {
          setOperatorFilterCookie(
            `hiddenChains~${action.payload.username}`,
            [],
          );
        }
        state.filteredSiteOperators = [];
      });
  },
});

export const {
  toggleHideChain,
  setHiddenChains,
  resetHiddenChains,
  setSiteFilterBy,
  setSiteFilterValue,
} = sitesSlice.actions;

export const selectFilteredSiteOperators = (state: RootState) =>
  state.sites.filteredSiteOperators;
export const selectSiteFilterBy = (state: RootState) => state.sites.filterBy;
export const selectSiteFilterValue = (state: RootState) =>
  state.sites.filterValue;

export default sitesSlice.reducer;
