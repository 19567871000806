import { useState, useEffect } from "react";
import SyncRoundedIcon from "@mui/icons-material/SyncRounded";
import { ButtonProps } from "@mui/material/Button";
import { useUpdateAlarmReportRecordMutation } from "../../features/remoteAccess/remoteAccessAPI";
import { useSnackbar } from "notistack";
import { getCookie, setCookie } from "../../utils/cookieHandlers";
import { DateTime } from "luxon";

const getCookieName = (siteId: number) => `syncedSiteAlarms-${siteId}`;

type Props = {
  siteId?: number;
  refetch?: () => void;
};

export default function useSyncSiteAlarms({ siteId, refetch }: Props):
  | (ButtonProps & {
      btnText: string;
      onClick: () => void;
    })
  | null {
  const { enqueueSnackbar } = useSnackbar();
  const [update] = useUpdateAlarmReportRecordMutation();

  const initLastAttempted = () => {
    if (siteId) {
      const cookieName = getCookieName(siteId);
      const cookie = getCookie(cookieName);
      if (cookie) {
        const dateTime = DateTime.fromISO(cookie);
        if (dateTime.isValid) {
          return dateTime;
        }
      }
    }
    return null;
  };
  const initRemainingTime = () => {
    if (siteId) {
      const cookieName = getCookieName(siteId);
      const cookie = getCookie(cookieName);
      if (cookie) {
        const dateTime = DateTime.fromISO(cookie);
        if (dateTime.isValid) {
          const diff = dateTime
            .plus({ minutes: 5 })
            .diffNow(["seconds"], {})
            .toObject().seconds;
          return diff ?? null;
        }
      }
    }
    return null;
  };

  const [lastAttempted, setLastAttempted] = useState<DateTime<true> | null>(
    initLastAttempted(),
  );
  const [remainingTime, setRemainingTime] = useState<number | null>(
    initRemainingTime(),
  );

  useEffect(() => {
    let myInterval: NodeJS.Timeout | undefined;

    if (lastAttempted) {
      // Need to add 5 minutes to the last attempted time
      myInterval = setInterval(() => {
        const diff = lastAttempted
          .plus({ minutes: 5 })
          .diffNow(["seconds"])
          .toObject().seconds;
        if (diff && diff <= 0) {
          setRemainingTime(null);
          clearInterval(myInterval);
          return;
        }
        setRemainingTime(diff ?? null);
      }, 1000);
    }

    return () => {
      if (myInterval) {
        clearInterval(myInterval);
      }
    };
  }, [lastAttempted]);

  const [isSyncing, setIsSyncing] = useState(false);
  const [syncingText, setSyncingText] = useState("Sync Site Alarms");

  useEffect(() => {
    if (isSyncing) {
      setSyncingText("Syncing Alarms...");
    } else {
      setSyncingText("Sync Site Alarms");
    }
  }, [isSyncing]);

  // I need to also try and limit the user from requesting this more often than 2-3 minutes
  const handleSyncSiteAlarms = async () => {
    if (!siteId || !refetch || isSyncing || remainingTime !== null) {
      return;
    }
    try {
      setIsSyncing(true);
      setLastAttempted(DateTime.now().toUTC());
      setCookie(
        getCookieName(siteId),
        DateTime.now().toUTC().toISO(),
        DateTime.now() // expires in 5 minutes
          .plus({
            minutes: 3,
          })
          .toUTC()
          .toHTTP(),
      );
      await update({ store_num: siteId });
      enqueueSnackbar("Site alarms syncing...", { variant: "success" });
      // wait for 30 seconds to allow for processing of the alarms ??
      await new Promise((resolve) => setTimeout(resolve, 30000));

      refetch();
    } catch (err) {
      console.error("Error syncing site alarms: ", err);
      enqueueSnackbar("Error syncing site alarms", { variant: "error" });
    } finally {
      setIsSyncing(false);
    }
  };

  if (!siteId || !refetch) {
    return null;
  }

  return {
    btnText: syncingText,
    startIcon: (
      <SyncRoundedIcon
        sx={{
          animation: isSyncing ? "spin 2s linear infinite" : "none",
          "@keyframes spin": {
            from: { transform: "rotate(0deg)" },
            to: { transform: "rotate(360deg)" },
          },
        }}
      />
    ),
    onClick: handleSyncSiteAlarms,
    variant: "contained",
    disabled: isSyncing || remainingTime !== null,
  };
}
