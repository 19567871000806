import { useState, useEffect } from 'react';

export default function useCheckIfPasswordsMatch(
  password: string,
  passwordCheck: string,
  delay = 1000,
) {
  const [passwordsDontMatch, setPasswordsDontMatch] = useState(false);
  useEffect(() => {
    let myTo: NodeJS.Timeout | undefined;
    if (password !== '') {
      if (password === passwordCheck) {
        setPasswordsDontMatch(false);
      } else {
        myTo = setTimeout(() => {
          setPasswordsDontMatch(true);
        }, delay);
      }
    } else {
      setPasswordsDontMatch(false);
    }

    return () => {
      clearTimeout(myTo);
    };
  }, [password, passwordCheck, delay]);

  return { passwordsDontMatch };
}
