import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TabletIcon from "@mui/icons-material/Tablet";
import Badge from "@mui/material/Badge";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton, {
  ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemIcon, { ListItemIconProps } from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { ElementType, memo, useMemo } from "react";
import { SiteControllersType } from "../../../../../features/sites/sitesAPI";
import { Link as RouterLink, To } from "react-router-dom";
import ControllerList from "./Controllers/ControllerList";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { toggleDrawer } from "../../../../../features/view/viewSlice";
import {
  selectCanViewFloorplanForSite,
  selectCanEditFloorplanForSite,
  selectCanRemoteAccessLeoForSite,
  selectIsLeoAlertAvailableForSite,
} from "../../../../../features/auth/authSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import AnnouncementIcon from "@mui/icons-material/Announcement";

type AlarmListItemButtonType = ListItemButtonProps & { inalarm: string } & {
  component: ElementType;
  to: To;
};

const AlarmListItemButton = styled(ListItemButton)<AlarmListItemButtonType>(
  ({ theme, inalarm }) => {
    if (inalarm === "true") {
      return {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        "&:hover": {
          backgroundColor: theme.palette.error.light, // was red['A200']
        },
      };
    }

    return {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      "&:hover": {
        backgroundColor: theme.palette.success.light, // was green['A200']
      },
    };
  },
);

export const CustomListItemIcon = styled(ListItemIcon)<ListItemIconProps>(
  ({ theme }) => ({
    minWidth: 0,
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(4.5),
    borderRadius: "100%",
    transition: theme.transitions.create(["background-color"]),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }),
);

type Props = {
  expand: boolean;
  controllers: SiteControllersType;
  controllersInAlarm: string[] | null;
  siteName: string;
  city: string;
  state: string;
  siteId: number;
  inAlarm: boolean;
  activeAlarmCount: number;
  leoInstalled: boolean;
  terminalModeEnabled: boolean;
  floorplanEnabled: boolean;
  scrollIntoFocus: () => void;
  scrollin: boolean;
  isMobileScreen: boolean;
};

const SiteMenuList = ({
  expand,
  controllers,
  siteName,
  siteId,
  city,
  state,
  inAlarm,
  activeAlarmCount,
  terminalModeEnabled,
  floorplanEnabled,
  leoInstalled,
  scrollIntoFocus,
  isMobileScreen,
  controllersInAlarm,
}: Props) => {
  const dispatch = useAppDispatch();

  const canRemoteAccessLeo = useAppSelector((state) =>
    selectCanRemoteAccessLeoForSite(state, siteId),
  );
  const showLeoLink = useMemo(() => {
    return terminalModeEnabled && leoInstalled && canRemoteAccessLeo;
  }, [terminalModeEnabled, leoInstalled, canRemoteAccessLeo]);

  const canViewFloorplan = useAppSelector((state) =>
    selectCanViewFloorplanForSite(state, siteId),
  );
  const canEditFloorplan = useAppSelector((state) =>
    selectCanEditFloorplanForSite(state, siteId),
  );
  const leoAlertAvailable = useAppSelector((state) =>
    selectIsLeoAlertAvailableForSite(state, siteId),
  );

  const showLeoAlertRecordsLink = useMemo(() => {
    return leoAlertAvailable;
  }, [leoAlertAvailable]);

  const showFlooplanLink = useMemo(() => {
    return floorplanEnabled && (canViewFloorplan || canEditFloorplan);
  }, [floorplanEnabled, canViewFloorplan, canEditFloorplan]);

  const siteNameAndLocationEncoded = useMemo(() => {
    return encodeURIComponent(`${siteName} - ${city}, ${state}`);
  }, [siteName, city, state]);

  const alarmsUrl = useMemo(() => {
    return `site/${siteId}/${siteNameAndLocationEncoded}/alarms`;
  }, [siteId, siteNameAndLocationEncoded]);

  const analyticsUrl = useMemo(() => {
    return `site/${siteId}/${siteNameAndLocationEncoded}/analysis`;
  }, [siteId, siteNameAndLocationEncoded]);

  const floorplansUrl = useMemo(() => {
    return `site/${siteId}/${siteNameAndLocationEncoded}/floorplans`;
  }, [siteId, siteNameAndLocationEncoded]);

  const leoUrl = useMemo(() => {
    return `site/${siteId}/${siteNameAndLocationEncoded}/terminalMode`;
  }, [siteId, siteNameAndLocationEncoded]);

  const configUrl = useMemo(() => {
    return `/site/${siteId}/${siteNameAndLocationEncoded}/settings/`;
  }, [siteId, siteNameAndLocationEncoded]);

  const handleLinkClick = () => {
    // if we are on mobile hide the menu on link click
    if (isMobileScreen) {
      // hide sidebar
      dispatch(toggleDrawer());
    }
  };

  return (
    <Collapse
      onEntered={(_n) => {
        // expand transition is done
        // scroll the store into view on the menu
        scrollIntoFocus();
      }}
      in={expand}
      timeout={300}
      sx={{
        backgroundColor: "action.selected",
        boxShadow: expand
          ? "inset 0px -10px 10px -10px rgba(0,0,0,0.25)"
          : "none",
      }}
    >
      <List disablePadding>
        <ListItem
          disablePadding
          secondaryAction={
            <Badge
              badgeContent={activeAlarmCount}
              color="warning"
              sx={{ marginRight: 1 }}
            />
          }
        >
          <AlarmListItemButton
            inalarm={inAlarm.toString()}
            component={RouterLink}
            to={alarmsUrl}
            onClick={handleLinkClick}
            aria-label="Navigate to this Site's Alarms Page"
          >
            <NotificationsActiveIcon
              sx={{ color: "#FFF", marginLeft: 5, marginRight: 1 }}
            />

            <ListItemText primary="Alarms" />
          </AlarmListItemButton>
        </ListItem>
      </List>

      {showLeoAlertRecordsLink && (
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              component={RouterLink}
              to={`/site/${siteId}/${siteNameAndLocationEncoded}/leoAlertRecords`}
              onClick={handleLinkClick}
              aria-label="Navigate to this Site's Leo Alert Records Page"
            >
              <AnnouncementIcon sx={{ marginLeft: 5, marginRight: 1 }} />

              <ListItemText primary="LEO Alert Records" />
            </ListItemButton>
          </ListItem>
        </List>
      )}

      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton
            component={RouterLink}
            to={analyticsUrl}
            onClick={handleLinkClick}
            aria-label="Navigate to this Site's Analytics Page"
          >
            <QueryStatsIcon sx={{ marginLeft: 5, marginRight: 1 }} />

            <ListItemText primary="Site Analysis" />
          </ListItemButton>
        </ListItem>
      </List>
      {showFlooplanLink && (
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              component={RouterLink}
              to={floorplansUrl}
              onClick={handleLinkClick}
              aria-label="Navigate to this Site's Floorplans Page"
            >
              <MapOutlinedIcon sx={{ marginLeft: 5, marginRight: 1 }} />

              <ListItemText primary="Floor plans" />
            </ListItemButton>
          </ListItem>
        </List>
      )}

      {showLeoLink && (
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              component={RouterLink}
              state={{ chosenDevice: "LEO" }}
              to={leoUrl}
              onClick={handleLinkClick}
              aria-label="Navigate to this Site's LEO Remote Acces Page"
            >
              <TabletIcon sx={{ marginLeft: 5, marginRight: 1 }} />

              <ListItemText primary="LEO" />
            </ListItemButton>
          </ListItem>
        </List>
      )}

      {/* Map through Controllers for that store */}
      {controllers && controllers.length > 0 && (
        <ControllerList
          controllers={controllers}
          siteId={siteId}
          siteNameAndLocationEncoded={siteNameAndLocationEncoded}
          handleLinkClick={handleLinkClick}
          controllersInAlarm={controllersInAlarm}
        />
      )}
      {configUrl !== null && (
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton
              component={RouterLink}
              to={configUrl}
              onClick={handleLinkClick}
              aria-label="Navigate to this Site's Settings Page"
            >
              <SettingsIcon sx={{ marginLeft: 5, marginRight: 1 }} />

              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
        </List>
      )}
    </Collapse>
  );
};

export default memo(SiteMenuList);
