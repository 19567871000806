import { memo } from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface LoadingProps {
  size?: string | number;
}

type Props = CircularProgressProps & LoadingProps;

const Loading = ({ size = "5rem", ...props }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "auto",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexGrow: 1,
      }}
      data-testid="loading"
    >
      <CircularProgress disableShrink size={size} {...props} />
    </Box>
  );
};

export default memo(Loading);
