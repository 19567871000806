import { useImperativeHandle, forwardRef, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  selectShowUnauthorizedSiteAccess,
  handleHideUnauthorizedSiteAccess,
} from "../../features/view/viewSlice";
import useTansitionNavigate from "../../hooks/useTransitionNavigate";
import Link from "@mui/material/Link";

type Ref = {
  focus: () => void;
} | null;
interface Props {
  handleClose: () => void;
}

const UnauthorizedSiteAccessContent = forwardRef<Ref, Props>(
  ({ handleClose }, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => ({
      focus() {
        buttonRef.current?.focus({ preventScroll: true });
      },
    }));

    return (
      <>
        <DialogTitle color="error">Unauthorized Site Access</DialogTitle>
        <DialogContent>
          <DialogContentText fontSize={18} marginTop={1} textAlign="center">
            You do not have authorization to view this site!
          </DialogContentText>
          <DialogContentText fontSize={18} marginTop={1} textAlign="center">
            <Link
              color="secondary"
              href="https://www.hunterliberty.com/contact-us-2"
            >
              Contact Hunter Liberty
            </Link>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              ":focus": {
                boxShadow: "0px 0px 19px 1px rgba(232,255,61,1)",
              },
            }}
            ref={buttonRef}
            variant="contained"
            onClick={handleClose}
          >
            OKAY
          </Button>
        </DialogActions>
      </>
    );
  },
);

const UnauthorizedSiteAccessDialog = () => {
  const navigate = useTansitionNavigate();
  const dispatch = useAppDispatch();
  // show dialog via selector
  const show = useAppSelector(selectShowUnauthorizedSiteAccess);

  const buttonHandle = useRef<Ref>(null);

  const handleClose = () => {
    navigate("/");
    dispatch(handleHideUnauthorizedSiteAccess());
  };

  const onClose = () => {
    // highlight acknowledge Button by focus
    console.log("Focusing ok button"); // need to see if this actually works
    buttonHandle.current?.focus();
  };

  return (
    <Dialog
      slotProps={{ backdrop: { sx: { backdropFilter: "blur(25px)" } } }}
      transitionDuration={0}
      open={show}
      onClose={onClose}
      scroll="paper"
    >
      <UnauthorizedSiteAccessContent
        ref={buttonHandle}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default UnauthorizedSiteAccessDialog;
