import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { SiteControllerType } from "../../../../../../features/sites/sitesAPI";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { CustomListItemIcon } from "../SiteMenuList";
import Controller from "./Controller";

type Props = {
  controllers: SiteControllerType[];
  controllersInAlarm: string[] | null;
  siteNameAndLocationEncoded: string;
  siteId: number;
  handleLinkClick: () => void;
};

const ControllerList = ({
  controllers,
  siteNameAndLocationEncoded,
  siteId,
  handleLinkClick,
  controllersInAlarm,
}: Props) => {
  const [showControllers, setShowControllers] = useState(false);
  const handleShowControllers = () => {
    setShowControllers(!showControllers);
  };

  return (
    <List disablePadding>
      <ListItem
        sx={showControllers ? { boxShadow: 5, zIndex: 3 } : {}}
        disablePadding
      >
        <ListItemButton
          onClick={handleShowControllers}
          selected={showControllers}
        >
          {showControllers ? (
            <CustomListItemIcon>
              <RemoveOutlinedIcon />
            </CustomListItemIcon>
          ) : (
            <CustomListItemIcon>
              <AddOutlinedIcon />
            </CustomListItemIcon>
          )}
          <ListItemText primary={"Controllers"} />
        </ListItemButton>
      </ListItem>
      <Collapse
        sx={{
          boxShadow: "inset 10px 10px 31px -15px rgba(0,0,0,0.75)",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
        in={showControllers}
        component="div"
        timeout="auto"
      >
        {/* Replace with 'controllers' to render all controllers and not group EVCO Devices */}
        {controllers?.map((controller) => {
          return (
            <Controller
              key={controller.controller_name}
              controller={controller}
              siteNameAndLocationEncoded={siteNameAndLocationEncoded}
              siteId={siteId}
              handleLinkClick={handleLinkClick}
              controllersInAlarm={controllersInAlarm}
            />
          );
        })}
      </Collapse>
    </List>
  );
};

export default ControllerList;
