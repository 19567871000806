import { ChangeEvent, memo, useMemo, useCallback } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import TransparentSelect from "../../../../Inputs/TransparentSelect";
import TransparentTextField from "../../../../Inputs/TransparentTextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { useAppSelector, useAppDispatch } from "../../../../../app/hooks";
import {
  selectSiteFilterBy,
  selectSiteFilterValue,
  setSiteFilterValue,
  setSiteFilterBy,
  SiteFilterBy,
} from "../../../../../features/sites/sitesSlice";
import { selectIsGlobalAdmin } from "../../../../../features/auth/authSlice";
import Fade from "@mui/material/Fade";

type FilterByList = {
  label: string;
  value: SiteFilterBy;
  type: "string" | "number" | "boolean";
}[];

type Props = {
  showFilter: boolean;
  menuAnchor: HTMLElement | null;
  handleHideFilter: () => void;
  filterStringDebounced: string;
};

const SiteFilter = ({
  showFilter,
  menuAnchor,
  handleHideFilter,
  filterStringDebounced,
}: Props) => {
  const isGlobalAdmin = useAppSelector(selectIsGlobalAdmin);
  const filterBy = useAppSelector(selectSiteFilterBy);
  const filterValue = useAppSelector(selectSiteFilterValue);
  const dispatch = useAppDispatch();

  const handleFilterByChange = (e: SelectChangeEvent<unknown>) => {
    const val = e.target.value as SiteFilterBy;
    if (filterBy === "offline") {
      // clear filterValue
      dispatch(setSiteFilterValue(""));
    }
    dispatch(setSiteFilterBy(val));
    if (val === "offline") {
      // set filterValue to true
      dispatch(setSiteFilterValue("true"));
    }
  };

  const handleFilterValueSelectChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      dispatch(setSiteFilterValue(e.target.value as string));
    },
    [dispatch],
  );

  const handleFilterValueChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setSiteFilterValue(e.target.value));
    },
    [dispatch],
  );

  const handleClear = () => {
    dispatch(setSiteFilterValue(""));
    handleHideFilter();
  };

  const filterByOptions = useMemo(() => {
    const filterByList: FilterByList = [
      {
        label: "All",
        value: "All",
        type: "string",
      },
      {
        label: "Site Name",
        value: "store_name",
        type: "string",
      },
      {
        label: "Site State",
        value: "store_state",
        type: "string",
      },
      {
        label: "Site City",
        value: "store_city",
        type: "string",
      },
      {
        label: "Site Order",
        value: "store_order",
        type: "number",
      },
      {
        label: "Site Operator",
        value: "store_operator",
        type: "string",
      },
    ];

    if (isGlobalAdmin) {
      filterByList.push({
        label: "Site ID",
        value: "store_num",
        type: "number",
      });
      filterByList.push({
        label: "Chain ID",
        value: "chain_id",
        type: "number",
      });
      filterByList.push({
        label: "LEO Version",
        value: "leo_version",
        type: "string",
      });
      filterByList.push({
        label: "MAC Address",
        value: "mac_address",
        type: "string",
      });
      filterByList.push({
        label: "Offline",
        value: "offline",
        type: "boolean",
      });
    }

    return filterByList;
  }, [isGlobalAdmin]);

  const inputType = useMemo(() => {
    const selectedOption = filterByOptions.find(
      ({ value }) => value === filterBy,
    );
    if (selectedOption) {
      return selectedOption.type;
    }
    return "string";
  }, [filterBy, filterByOptions]);

  const Input = useMemo(() => {
    if (inputType === "boolean") {
      return (
        <FormControl>
          <InputLabel id="select-filter-value">Value</InputLabel>
          <TransparentSelect
            labelId="select-filter-value"
            size="small"
            label="Value"
            value={filterValue}
            onChange={handleFilterValueSelectChange}
            autoFocus
            sx={{ width: 190 }}
          >
            <MenuItem value="true">True</MenuItem>
            <MenuItem value="false">False</MenuItem>
          </TransparentSelect>
        </FormControl>
      );
    }

    return (
      <TransparentTextField
        size="small"
        label="Value"
        value={filterValue}
        onChange={handleFilterValueChange}
        autoFocus
        sx={{ width: 190 }}
        slotProps={{
          input: {
            endAdornment: (
              <Fade in={filterValue !== filterStringDebounced}>
                <InputAdornment position="end">
                  <AutorenewOutlinedIcon
                    sx={{
                      "@keyframes spin": {
                        "0%": {
                          transform: "rotate(0deg)",
                        },
                        "100%": {
                          transform: "rotate(360deg)",
                        },
                      },
                      animation: "spin 1s infinite linear",
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  />
                </InputAdornment>
              </Fade>
            ),
          },
        }}
      />
    );
  }, [
    inputType,
    filterValue,
    filterStringDebounced,
    handleFilterValueChange,
    handleFilterValueSelectChange,
  ]);

  return (
    <Popover
      open={showFilter}
      anchorEl={menuAnchor}
      onClose={handleHideFilter}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableRestoreFocus
    >
      <Stack
        padding={2}
        columnGap={1}
        rowGap={1}
        direction={{ sm: "column", md: "row" }}
        alignItems={{ sm: "flex-start", md: "stretch" }}
      >
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={handleClear}
        >
          Clear
        </Button>
        <FormControl>
          <InputLabel id="filter-by-label">Filter By</InputLabel>
          <TransparentSelect
            size="small"
            value={filterBy}
            onChange={handleFilterByChange}
            labelId="filter-by-label"
            id="filter-by-select"
            label="Filter By"
            sx={{ maxWidth: 200 }}
          >
            {filterByOptions.map((opt) => (
              <MenuItem key={opt.label} value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </TransparentSelect>
        </FormControl>
        {Input}

        <Button size="small" variant="contained" onClick={handleHideFilter}>
          Filter
        </Button>
      </Stack>
    </Popover>
  );
};

export default memo(SiteFilter);
