import { Dispatch, SetStateAction, MouseEvent, useState } from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SiteFilter from "./SiteFilter";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch } from "../../../../../app/hooks";
import { toggleDrawer } from "../../../../../features/view/viewSlice";
import SiteOperatorFilter from "./SiteOperatorFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Badge from "@mui/material/Badge";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type Props = {
  handleStoresDrawerToggle: () => void;
  handleSetSortASC: () => void;
  handleSetSortDESC: () => void;
  sortDirection: "ASC" | "DESC" | null;
  filterStringDebounced: string;
  showSiteOperatorFilter: boolean;
  setShowSiteOperatorFilter: Dispatch<SetStateAction<boolean>>;
  operators: string[];
  filteredSiteOperators: string[];
  handleFilterSiteOperator: (operator: string) => void;
  handleFilterAllSiteOperators: (operators: string[]) => void;
  handleUnfilterAllSiteOperators: () => void;
};

const SiteListHeader = ({
  handleStoresDrawerToggle,
  handleSetSortASC,
  handleSetSortDESC,
  sortDirection,
  filterStringDebounced,
  showSiteOperatorFilter,
  setShowSiteOperatorFilter,
  operators,
  filteredSiteOperators,
  handleFilterSiteOperator,
  handleFilterAllSiteOperators,
  handleUnfilterAllSiteOperators,
}: Props) => {
  const dispatch = useAppDispatch();

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };

  const [showFilter, setShowFilter] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const handleShowFilter = () => setShowFilter(true);
  const handleHideFilter = () => setShowFilter(false);

  const handleFilterMenuClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (menuAnchor === null) {
      setMenuAnchor(e.currentTarget);
    }
    handleShowFilter();
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        zIndex: 2,
        paddingBottom: 1,
        rowGap: 1,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginX: 1,
          marginTop: 1,
          alignItems: "flex-start",
        }}
      >
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleStoresDrawerToggle}
          color="primary"
          variant="contained"
        >
          Main Menu
        </Button>
        <IconButton
          size="small"
          title="hide menu"
          onClick={handleToggleDrawer}
          sx={{
            marginRight: "-5.5px",
          }}
        >
          <CloseRoundedIcon fontSize="large" />
        </IconButton>
      </Box>

      <Box position="relative">
        <Typography
          variant="h5"
          component="h4"
          textAlign="center"
          position="relative"
          margin="auto"
          display="flex"
          gap={1}
          justifyContent={"center"}
        >
          Sites
          <IconButton
            title="Change sorting direction of sites"
            size="small"
            onClick={
              sortDirection === "ASC" ? handleSetSortDESC : handleSetSortASC
            }
          >
            <ArrowDownwardIcon
              sx={{
                transition: "transform 0.15s ease-in-out",
                ...(sortDirection === "ASC" && {
                  transform: "rotate(-180deg)",
                }),
              }}
            />
          </IconButton>
        </Typography>

        <IconButton
          id="sites-filter-menu-button"
          title="Filter Sites"
          onClick={handleFilterMenuClick}
          aria-controls={showFilter ? "sitesFilterMenu" : undefined}
          aria-haspopup="true"
          aria-expanded={showFilter ? "true" : undefined}
          size="small"
          sx={{
            position: "absolute",
            right: (theme) => theme.spacing(1),
            bottom: 0,
            // transform: "translate(-50%, -50%)"
          }}
        >
          <Badge
            color="secondary"
            variant="dot"
            invisible={filterStringDebounced.length === 0}
            overlap="circular"
          >
            <FilterListIcon />
          </Badge>
        </IconButton>

        <SiteFilter
          filterStringDebounced={filterStringDebounced}
          menuAnchor={menuAnchor}
          handleHideFilter={handleHideFilter}
          showFilter={showFilter}
        />
      </Box>

      <SiteOperatorFilter
        operators={operators}
        handleFilterSiteOperator={handleFilterSiteOperator}
        filteredSiteOperators={filteredSiteOperators}
        setShowSiteOperatorFilter={setShowSiteOperatorFilter}
        showSiteOperatorFilter={showSiteOperatorFilter}
        handleFilterAllSiteOperators={handleFilterAllSiteOperators}
        handleUnfilterAllSiteOperators={handleUnfilterAllSiteOperators}
      />
    </Paper>
  );
};

export default SiteListHeader;
