import { useEffect, useRef } from 'react';
import { GetSitesResponse, SiteType } from '../../features/sites/sitesAPI';
import { DateTime } from 'luxon';
import { useAppDispatch } from '../../app/hooks';
import {
  IExpiredStoresList,
  showExpiredStoresDialog,
} from '../../features/view/viewSlice';

const COOKIE_NAME = 'expiredStoreNums';

const useCheckExpiredStores = (stores: GetSitesResponse | undefined) => {
  const dispatch = useAppDispatch();
  const cookies = document.cookie.split(';');

  const hasChecked = useRef(false);
  useEffect(() => {
    if (stores && !hasChecked.current) {
      hasChecked.current = true;
      const today = DateTime.now()
        .setZone('utc')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const expiredStores: SiteType[] = [];

      for (const store of stores) {
        if (store.cloud_end_date && store.leo_installed) {
          const expirationDate = DateTime.fromISO(store.cloud_end_date).setZone(
            'utc',
          );
          if (today >= expirationDate) {
            expiredStores.push(store);
          }
        }
      }

      let cookieExists = false;
      const unWarnedExpiredStores: SiteType[] = [];

      for (const cookie of cookies) {
        const [cookieName, cookieVal] = cookie.trim().split('=');
        if (cookieName === COOKIE_NAME) {
          cookieExists = true;
          const storeNums = cookieVal.split('~');
          for (const store of expiredStores) {
            const existsInCookies = storeNums.find(
              (num) => num === `${store.store_num}`,
            );
            if (!existsInCookies) {
              unWarnedExpiredStores.push(store);
            }
          }
        }
      }

      if (cookieExists) {
        if (unWarnedExpiredStores.length > 0) {
          const storeList: IExpiredStoresList = unWarnedExpiredStores.map(
            (s) => ({
              storeName: s.store_name,
              storeNum: s.store_num,
              expirationDate: DateTime.fromISO(s.cloud_end_date as string)
                .setZone('utc')
                .toLocaleString(),
            }),
          );
          // set cookies
          document.cookie = `${COOKIE_NAME}=${expiredStores
            .map((v) => v.store_num)
            .join('~')}; max-age=86400; path=/; samesite=strict;`;

          // show warning dialog
          dispatch(showExpiredStoresDialog(storeList));
        }
      } else {
        const storeList: IExpiredStoresList = expiredStores.map((s) => ({
          storeName: s.store_name,
          storeNum: s.store_num,
          expirationDate: DateTime.fromISO(s.cloud_end_date as string)
            .setZone('utc')
            .toLocaleString(),
        }));
        if (storeList.length > 0) {
          // set cookies
          document.cookie = `${COOKIE_NAME}=${expiredStores
            .map((v) => v.store_num)
            .join('~')}; max-age=86400; path=/; samesite=strict;`;

          // show warning dialog
          dispatch(showExpiredStoresDialog(storeList));
        }
      }
    }
  }, [stores, cookies, dispatch]);
};

export default useCheckExpiredStores;
