import { api } from "../../app/services/api";

export type PortType =
  | "WEB PORT"
  | "SSH PORT"
  | "FSD PORT"
  | "MONITORING PORT"
  | "REM CLIENT PORT"
  | "XPRA PORT";
export type GetAvailablePortsRequest = {
  store_num: number;
};

export type RemoteAccessPortInfo = {
  active: 0 | 1;
  port_num: number;
  port_type: PortType;
  start_date: string;
};

export type RemoteAccessDeviceInfo = {
  controller_name: string;
  controller_type: string;
  ports: RemoteAccessPortInfo[];
};

export type GetAvailablePortsResponse = RemoteAccessDeviceInfo[] | null;
export type StartTunnelResponse = GetAvailablePortsResponse;
export type StopTunnelResponse = {
  status: string;
};
export type StartXPRAResponse = StopTunnelResponse;

export type XPRARequestType = GetAvailablePortsRequest & {
  controller_name: string;
};

export type RebootLeoRequest = {
  store_num: number;
};
export type RebootLeoResponse = {
  status: "success" | "failed";
};
export type GetClientStatusRequest = {
  store_list: number[];
};
export type GetClientStatusResponse = {
  store_num: number;
  last_communicated: string; // ISO Format
}[];

export type UnregisteredLeo = {
  store_num: number;
  mac_address: string;
  leo_name: string | null;
};

export type ParsedUnregisteredLeo = {
  store_num: number;
  mac_address: string;
  leo_name: string;
};
export type GetUnregisteredLeosResponse = ParsedUnregisteredLeo[];
export type GetUnregisteredLeosInitResponse = UnregisteredLeo[];

export const remoteAccessApi = api.injectEndpoints({
  endpoints: (builder) => ({
    queryAvailablePorts: builder.query<
      StartTunnelResponse,
      GetAvailablePortsRequest
    >({
      query: (args) => ({
        url: "tm/start-tunnel",
        method: "POST",
        body: args,
      }),
      transformErrorResponse: (err, meta, arg) => {
        console.log({ err, meta, arg });
        return err;
      },
    }),
    startTunnel: builder.mutation<
      StartTunnelResponse,
      GetAvailablePortsRequest
    >({
      query: (args) => ({
        url: "tm/start-tunnel",
        method: "POST",
        body: args,
      }),
    }),
    stopTunnel: builder.mutation<StopTunnelResponse, GetAvailablePortsRequest>({
      query: (args) => ({
        url: "tm/stop-tunnel",
        method: "POST",
        body: args,
      }),
    }),
    startXpra: builder.mutation<StartXPRAResponse, XPRARequestType>({
      query: (args) => ({
        url: "tm/start-xpra",
        method: "POST",
        body: args,
      }),
    }),
    stopXpra: builder.mutation<unknown, XPRARequestType>({
      query: (args) => ({
        url: "tm/stop-xpra",
        method: "POST",
        body: args,
      }),
    }),
    rebootLeo: builder.mutation<RebootLeoResponse, RebootLeoRequest>({
      query: (body) => ({
        url: "tm/reboot-leo",
        method: "POST",
        body,
      }),
    }),
    getClientStatus: builder.query<
      GetClientStatusResponse,
      GetClientStatusRequest
    >({
      query: (body) => ({
        url: "tm/client-status",
        method: "POST",
        body,
      }),
    }),
    refreshControllersList: builder.mutation<unknown, { store_num: number }>({
      query: (body) => ({
        url: "tm/update-controller-list",
        method: "POST",
        body,
      }),
    }),
    updateAlarmReportRecord: builder.mutation<unknown, { store_num: number }>({
      query: (body) => ({
        url: "tm/update-alarm-report-record",
        method: "POST",
        body,
      }),
    }),
    updateDataEmailRecord: builder.mutation<unknown, { store_num: number }>({
      query: (body) => ({
        url: "tm/update-data-email-record",
        method: "POST",
        body,
      }),
    }),

    getUnregisteredLeos: builder.query<GetUnregisteredLeosResponse, void>({
      query: () => ({
        url: "tm/unregistered-leo",
        method: "GET",
      }),
      transformResponse: (res: GetUnregisteredLeosInitResponse) => {
        return res.map((leo) => ({
          ...leo,
          leo_name: leo.leo_name
            ? `${leo.leo_name} (${leo.mac_address})`
            : `UNKNOWN (${leo.mac_address})`,
        }));
      },
      providesTags: ["UnregisteredLeos"],
    }),
  }),
});

export const {
  useLazyQueryAvailablePortsQuery,
  useQueryAvailablePortsQuery,
  useStartTunnelMutation,
  useStopTunnelMutation,
  useStartXpraMutation,
  useStopXpraMutation,
  useRebootLeoMutation,
  useGetClientStatusQuery,
  useRefreshControllersListMutation,
  useUpdateAlarmReportRecordMutation,
  useUpdateDataEmailRecordMutation,

  useGetUnregisteredLeosQuery,
} = remoteAccessApi;
