import { forwardRef } from "react";
import { styled } from "@mui/material/styles";
import Select, { SelectProps } from "@mui/material/Select";
import { inputBaseClasses } from "@mui/material/InputBase";

const TransparentSelect = styled(
  forwardRef<HTMLDivElement, SelectProps>((props, ref) => (
    <Select ref={ref} {...props} color="secondary" />
  )),
)<SelectProps>(({ theme }) => ({
  [`&.${inputBaseClasses.root}`]: {
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,0.1)"
        : "rgba(0,0,255,0.1)",
    transition: theme.transitions.create(["background-color", "border-color"]),
    [`&.${inputBaseClasses.focused}`]: {
      backgroundColor: "transparent",
    },
  },
}));

export default TransparentSelect;
