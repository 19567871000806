type EndPointKeyType = {
  [name: string]: string;
};

export const endPointKeys: EndPointKeyType = {
  getallusers: "All Users",
  getStorelist: "Site List",
  getalarms: "Alarms",
  getStores: "Site List",
  getstoreminimal: "Shortened Site List",
  managestore: "Creat/Update Site",
  getchaininfo: "Chain Information",
  getofflinestores: "Offline Sites",
  login: "Login",
  getsitealarmanalysis: "Site Alarm Analysis",
  getalarmscount: "Alarms Count",
  rackusage: "Rack Usage",
  getAlarmCountPerYear: "Historical Alarm Counts",
  "getanalytics/rackusage": "Rack Usage",
  getAnalyticsCaseTemp: "Case Temps",
  casetemp: "Case Temps",
  "getanalytics/casetemp": "Case Temps",
  liquidlevel: "Liquid Level",
  "getanalytics/liquidlevel": "Liquid Level",
  getlatestcontrollerdata: "Controller Data",
  applications: "Applications Graph",
  weather: "Ambient Temperature",
  "graph/applications": "Application Graphs",
  "graph/weather": "Abient Temperature",
  "graph/applications/alarms": "Graph Alarms",
  updateapplicationcelltype: "Update Cell Type",
  floorplans: "Floor plans",
  "floorplan/floorplans": "Floor plans",
  "floorplan/managefloorplans": "Create/Update Floor plan",
  "floorplan/deletefloorplan": "Delete Floor plan",
  "floorplan/appmarkerlist": "Floor plan Marker Options",
  "floorplan/appmarkers": "Floor plan Markers",
  "floorplan/manageappmarkers": "Create/Update Floor plan Marker",
  "floorplan/deletefloorplanappmarker": "Delete Floor plan Marker",
  "tm/ports": "Terminal Mode Ports",
  "tm/starttunnel": "Start Terminal Mode Tunnel",
  "tm/stoptunnel": "Stop Terminal Mode Tunnel",
  "tm/startxpra": "Start XPRA",
  "tm/stopxpra": "Stop XPRA",
  queryAvailablePorts: "Connection Details",
};

export const returnEndPointKey = (key: string) => {
  if (endPointKeys[key]) {
    return endPointKeys[key];
  }
  console.info(`%cHandle undefined endpoint key: %c${key}`, "color: red");
  return key;
};
