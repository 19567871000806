import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import PasswordInput from "../../../components/Inputs/PasswordInput";
import {
  selectAuthToken,
  selectUsername,
} from "../../../features/auth/authSlice";
import { useResetPasswordMutation } from "../../../features/users/usersAPI";
import useCheckIfPasswordsMatch from "../../../hooks/useCheckIfPasswordsMatch";
import useCheckIfMatchThenNavigate from "../../../hooks/appHooks/useCheckIfMatchThenNavigate";
import FormHelperText from "@mui/material/FormHelperText";
import { validPasswordRegex } from "../../../constants/constants";
import { useSnackbar } from "notistack";

const ResetPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();

  const username = useAppSelector(selectUsername);
  const token = useAppSelector(selectAuthToken);

  const navigateAway = useCheckIfMatchThenNavigate(
    token === "" || username === "",
    "/login",
  );

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const { passwordsDontMatch } = useCheckIfPasswordsMatch(
    newPassword,
    confirmPassword,
  );

  if (navigateAway) {
    return navigateAway;
  }

  const handleTextChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    if (name === "newPassword") {
      setNewPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (
      e.target instanceof HTMLFormElement &&
      e.target.reportValidity() &&
      newPassword === confirmPassword
    ) {
      if (newPassword.match(validPasswordRegex) === null) {
        enqueueSnackbar(
          "Your password must be at least 8 characters long and include at least one capital letter and one number.",
          {
            variant: "error",
            autoHideDuration: 12000,
          },
        );
        return;
      }

      const res = await resetPassword({ password: newPassword }).unwrap();
      if (res.status === "success") {
        navigate("/login", {
          state: {
            redirectTo: location?.state?.redirectTo ?? undefined,
          },
        });
      }
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        mt: 8,
        mb: 1,
        position: "relative",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <PasswordOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <Box component="form" sx={{ mt: 1 }} onSubmit={handleSubmit}>
          <PasswordInput
            margin="normal"
            required
            fullWidth
            id="newPassword"
            label="New Password"
            name="newPassword"
            autoComplete="newPassword"
            autoFocus
            value={newPassword}
            onChange={handleTextChange}
            error={passwordsDontMatch}
          />

          <PasswordInput
            margin="normal"
            required
            fullWidth
            id="confirmPassword"
            label="Confirm Password"
            name="confirmPassword"
            autoComplete="confirmPassword"
            value={confirmPassword}
            onChange={handleTextChange}
            error={passwordsDontMatch}
            helperText={
              passwordsDontMatch ? "Passwords must match!" : undefined
            }
          />

          <FormHelperText sx={{ marginX: 1.5 }}>
            Your password must be at least 8 characters long and include at
            least one capital letter and one number.
          </FormHelperText>

          <LoadingButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </LoadingButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              href="https://www.hunterliberty.com/contact-us-2"
              color="secondary"
            >
              Contact Us
            </Link>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResetPassword;
