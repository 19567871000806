import { FallbackProps } from "react-error-boundary";
import { useEffect, useMemo } from "react";

/**
 * @description Fallback Component for ReactErrorBoundary. Probably will never display but just in case. React Router Dom catches most of the unhandle Errors
 * @author Jacob Cutshall
 */
const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const isChunkLoadError = useMemo(
    () =>
      (error.message as string).match(/Loading chunk [\d]+ failed/) !== null,
    [error.message],
  );

  useEffect(() => {
    let myTo: NodeJS.Timeout | undefined;
    if (isChunkLoadError) {
      myTo = setTimeout(() => {
        window.location.reload();
      }, 10000);
    }

    return () => {
      if (myTo) {
        clearTimeout(myTo);
      }
    };
  }, [isChunkLoadError]);

  if (isChunkLoadError) {
    return (
      <div role="alert">
        <p>
          The Application has been updated. Please Refresh the page or wait 10
          seconds.
        </p>
        <button onClick={() => window.location.reload()}>Refresh</button>
      </div>
    );
  }

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

export default ErrorFallback;
