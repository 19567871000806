import CloudOffIcon from "@mui/icons-material/CloudOff";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { SiteControllerType } from "../../../../../../features/sites/sitesAPI";

type Props = {
  controller: SiteControllerType;
  controllersInAlarm: string[] | null;
  siteNameAndLocationEncoded: string;
  siteId: number;
  handleLinkClick: () => void;
};

const Controller = ({
  controller,
  siteNameAndLocationEncoded,
  siteId,
  handleLinkClick,
  controllersInAlarm,
}: Props) => {
  const controllerUrl = useMemo(() => {
    return `site/${siteId}/${siteNameAndLocationEncoded}/controller/${encodeURIComponent(
      controller.controller_name,
    )}`;
  }, [siteId, siteNameAndLocationEncoded, controller]);

  const inAlarm = useMemo(() => {
    if (controllersInAlarm) {
      return controllersInAlarm.includes(controller.controller_name);
    }

    return false;
  }, [controllersInAlarm, controller]);

  return (
    <List key={controller.controller_name} disablePadding>
      <ListItem disablePadding>
        <ListItemButton
          component={RouterLink}
          to={controllerUrl}
          onClick={handleLinkClick}
          aria-label="Navigate to this Controller's Page"
          sx={[
            inAlarm && {
              backgroundColor: (theme) => theme.palette.error.main,
              color: (theme) => theme.palette.error.contrastText,
              boxShadow: "inset 11px 1px 21px -15px rgba(0,0,0,0.75)",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.error.light,
              },
            },
            {
              "&:last-of-type": {
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              },
            },
          ]}
        >
          <ListItemText
            sx={{ marginLeft: 8.5 }}
            primary={`${
              controller.controller_type?.replace(" V2", "") ?? ""
            } - ${controller.controller_name}`}
          />
          {controller.offline === 1 && (
            <ListItemIcon>
              <CloudOffIcon
                sx={[
                  inAlarm && {
                    color: (theme) => theme.palette.error.contrastText,
                  },
                ]}
              />
            </ListItemIcon>
          )}
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default Controller;
