import { MapContainer } from "react-leaflet";
import { useTheme } from "@mui/material/styles";
import "leaflet/dist/leaflet.css";
import { useMemo } from "react";
import { GetSitesResponse } from "../../features/sites/sitesAPI";

import LeafletMap from "./LeafletMap";

type Props = {
  sites: GetSitesResponse | undefined;
};

const LeafletMapContainer = ({ sites }: Props) => {
  const theme = useTheme();

  const style = useMemo(
    () => ({
      width: "100%",
      height: "100%",
      borderRadius: theme.shape.borderRadius,
      minHeight: 300,
      boxShadow: theme?.shadows[8],
      flexGrow: 1,
    }),
    [theme],
  );

  return (
    <MapContainer
      style={style}
      center={[34.05125, -20.721107]}
      zoom={5}
      scrollWheelZoom={true}
      trackResize
    >
      <LeafletMap sites={sites ? sites : []} />
    </MapContainer>
  );
};

export default LeafletMapContainer;
