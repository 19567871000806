function getBaseApiUrl() {
  if (import.meta.env.VITEST_POOL_ID !== undefined) {
    return "http://10.1.10.100:5000/";
  }

  if (import.meta.env.PROD) {
    return "https://leocloud-flask-v2.azurewebsites.net";
  }

  return "https://leocloud-flask-v2.azurewebsites.net";
}

const baseApiUrl = getBaseApiUrl();

export default baseApiUrl;
