export enum AlarmsTabs {
  RECENT = "recent",
  ACTIVE = "active",
  OVERRIDE = "override",
  HISTORY = "history",
  TEST = "test",
}

export const nonFilteredAlarmStrings = [
  "rack fail dishcharge trip",
  "rack fail phase fail",
  "refr phase loss",
  "high discharge limit exceeded",
  "high suction limit exceeded",
  "case temp hi limit exceeded",
  "discharge trip",
  "leo cannot communicate with leocloud",
];
export const deviceAbsentValidApps = ["16ai", "8ro", "4ao", "8do"];
