import Grid, { Grid2Props as GridProps } from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";

const SingleItemGridContainer = styled((props) => (
  <Grid container {...props} />
))<GridProps>(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
}));

export default SingleItemGridContainer;
