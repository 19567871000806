import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Define keyframes for the new glow animation
const glow = keyframes`
  0%, 100% {
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.3), 0 0 6px rgba(255, 255, 255, 0.2), 0 0 9px rgba(255, 255, 255, 0.1);
  }
  50% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.3), 0 0 15px rgba(255, 255, 255, 0.2);
  }
`;

const BannerWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 15,
  right: -35,
  width: 150,
  transform: "rotate(45deg)",
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  textAlign: "center",
  lineHeight: "30px",
  letterSpacing: 1,
  fontSize: 14,
  fontWeight: "bold",
  boxShadow: "0 0 3px rgba(0,0,0,0.3)",
  zIndex: 1,
  transition: "all 0.3s ease-in-out",
  animation: `${glow} 3s infinite ease-in-out`,
  background: `linear-gradient(90deg, ${theme.palette.error.main} 0%, ${theme.palette.error.light} 50%, ${theme.palette.error.main} 100%)`,
  backgroundSize: "200% 100%",
  "&::before, &::after": {
    content: '""',
    position: "absolute",
    top: "100%",
    left: 0,
    zIndex: -1,
  },
  "&::before": {
    borderLeft: `3px solid ${theme.palette.error.dark}`,
    borderRight: "3px solid transparent",
    borderBottom: "3px solid transparent",
    borderTop: `3px solid ${theme.palette.error.dark}`,
  },
  "&::after": {
    borderLeft: "3px solid transparent",
    borderRight: `3px solid ${theme.palette.error.dark}`,
    borderBottom: "3px solid transparent",
    borderTop: `3px solid ${theme.palette.error.dark}`,
    right: 0,
  },
}));

const Container = styled(Box)({
  position: "relative",
  overflow: "hidden",
});

interface NewFeatureBannerProps {
  children?: React.ReactNode;
}

const NewFeatureBanner: React.FC<NewFeatureBannerProps> = ({ children }) => {
  return (
    <Container>
      <BannerWrapper>
        <Typography variant="body2" component="div">
          New
        </Typography>
      </BannerWrapper>
      {children}
    </Container>
  );
};

export default NewFeatureBanner;
