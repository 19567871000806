import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

const DoesNotExist = () => {
  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h2" component="h1">
        The page you are looking for does not exist!
      </Typography>
      <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        endIcon={<HomeIcon />}
        aria-label="Back to Home"
      >
        Back to Home
      </Button>
    </Container>
  );
};

export default DoesNotExist;
